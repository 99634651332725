<div class="card-header border-0" [ngClass]="'bg-' + chartColor">
  <h3 class="card-title fw-bolder text-white">Sales Progress</h3>

  <div class="card-toolbar">
    <!-- begin::Menu  -->
    <button
      type="button"
      class="
        btn btn-sm btn-icon btn-color-white btn-active-white
        border-0
        me-n3
      "
      [ngClass]="'btn-active-color-' + chartColor"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <app-keenicon name="category" class="fs-2"></app-keenicon>
    </button>
    <app-dropdown-menu1></app-dropdown-menu1>
    <!-- end::Menu  -->
  </div>
</div>
<!-- end::Header  -->

<!-- begin::Body  -->
<div class="card-body p-0">
  <!-- begin::Chart  -->
  <div
    #chartRef
    class="mixed-widget-12-chart card-rounded-bottom"
    [ngClass]="'bg-' + chartColor"
    [style.height]="chartHeight"
  >
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [dataLabels]="chartOptions.dataLabels"
      [stroke]="chartOptions.stroke"
      [legend]="chartOptions.legend"
      [fill]="chartOptions.fill"
      [states]="chartOptions.states"
      [tooltip]="chartOptions.tooltip"
      [colors]="chartOptions.colors"
      [markers]="chartOptions.markers"
      [plotOptions]="chartOptions.plotOptions"
    ></apx-chart>
  </div>
  <!-- end::Chart  -->

  <!-- begin::Stats  -->
  <div class="card-rounded bg-body mt-n10 position-relative card-px py-15">
    <!-- begin::Row  -->
    <div class="row g-0 mb-7">
      <!-- begin::Col  -->
      <div class="col mx-5">
        <div class="fs-6 text-gray-500">Avarage Sale</div>
        <div class="fs-2 fw-bolder text-gray-800">$650</div>
      </div>
      <!-- end::Col  -->

      <!-- begin::Col  -->
      <div class="col mx-5">
        <div class="fs-6 text-gray-500">Comissions</div>
        <div class="fs-2 fw-bolder text-gray-800">$29,500</div>
      </div>
      <!-- end::Col  -->
    </div>
    <!-- end::Row  -->

    <!-- begin::Row  -->
    <div class="row g-0">
      <!-- begin::Col  -->
      <div class="col mx-5">
        <div class="fs-6 text-gray-500">Revenue</div>
        <div class="fs-2 fw-bolder text-gray-800">$55,000</div>
      </div>
      <!-- end::Col  -->

      <!-- begin::Col  -->
      <div class="col mx-5">
        <div class="fs-6 text-gray-500">Expenses</div>
        <div class="fs-2 fw-bolder text-gray-800">$1,130,600</div>
      </div>
      <!-- end::Col  -->
    </div>
    <!-- end::Row  -->
  </div>
  <!-- end::Stats  -->
</div>
