<!--begin::Card widget 17-->
<div class="card card-flush" [ngClass]="cssClass">
  <!--begin::Header-->
  <div class="card-header pt-5">
    <!--begin::Title-->
    <div class="card-title d-flex flex-column">
      <!--begin::Info-->
      <div class="d-flex align-items-center">
        <!--begin::Currency-->
        <span class="fs-4 fw-semibold text-gray-500 me-1 align-self-start">$</span>
        <!--end::Currency-->

        <!--begin::Amount-->
        <span class="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">69,700</span>
        <!--end::Amount-->

        <!--begin::Badge-->
        <span class="badge badge-light-success fs-base">
          <app-keenicon name="arrow-up" class="fs-5 text-success ms-n1"></app-keenicon>
          2.2%
        </span>
        <!--end::Badge-->
      </div>
      <!--end::Info-->

      <!--begin::Subtitle-->
      <span class="text-gray-500 pt-1 fw-semibold fs-6">Projects Earnings in April</span>
      <!--end::Subtitle-->
    </div>
    <!--end::Title-->
  </div>
  <!--end::Header-->

  <!--begin::Card body-->
  <div class="card-body pt-2 pb-4 d-flex flex-wrap align-items-center">
    <!--begin::Chart-->
    <div class="d-flex flex-center me-5 pt-2">
      <div id="kt_card_widget_17_chart"
        [ngStyle]="{'min-width': chartSize + 'px', 'min-height': chartSize + 'px'}"
        [attr.data-kt-size]="chartSize"
        [attr.data-kt-line]="chartLine">
      </div>
    </div>
    <!--end::Chart-->

    <!--begin::Labels-->
    <div class="d-flex flex-column content-justify-center flex-row-fluid">
      <!--begin::Label-->
      <div class="d-flex fw-semibold align-items-center">
        <!--begin::Bullet-->
        <div class="bullet w-8px h-3px rounded-2 bg-success me-3"></div>
        <!--end::Bullet-->

        <!--begin::Label-->
        <div class="text-gray-500 flex-grow-1 me-4">Leaf CRM</div>
        <!--end::Label-->

        <!--begin::Stats-->
        <div class="fw-bolder text-gray-700 text-xxl-end">$7,660</div>
        <!--end::Stats-->
      </div>
      <!--end::Label-->

      <!--begin::Label-->
      <div class="d-flex fw-semibold align-items-center my-3">
        <!--begin::Bullet-->
        <div class="bullet w-8px h-3px rounded-2 bg-primary me-3"></div>
        <!--end::Bullet-->

        <!--begin::Label-->
        <div class="text-gray-500 flex-grow-1 me-4">Mivy App</div>
        <!--end::Label-->

        <!--begin::Stats-->
        <div class="fw-bolder text-gray-700 text-xxl-end">$2,820</div>
        <!--end::Stats-->
      </div>
      <!--end::Label-->

      <!--begin::Label-->
      <div class="d-flex fw-semibold align-items-center">
        <!--begin::Bullet-->
        <div class="bullet w-8px h-3px rounded-2 me-3" style="background-color: #E4E6EF"></div>
        <!--end::Bullet-->

        <!--begin::Label-->
        <div class="text-gray-500 flex-grow-1 me-4">Others</div>
        <!--end::Label-->

        <!--begin::Stats-->
        <div class=" fw-bolder text-gray-700 text-xxl-end">$45,257</div>
        <!--end::Stats-->
      </div>
      <!--end::Label-->
    </div>
    <!--end::Labels-->
  </div>
  <!--end::Card body-->
</div>
<!--end::Card widget 17-->
