<!--begin::Exolore drawer-->
<div
  id="kt_engage_demos"
  class="bg-body"
  data-kt-drawer="true"
  data-kt-drawer-name="explore"
  data-kt-drawer-activate="true"
  data-kt-drawer-overlay="true"
  data-kt-drawer-width="{default:'350px', 'lg': '475px'}"
  data-kt-drawer-direction="end"
  data-kt-drawer-toggle="#kt_engage_demos_toggle"
  data-kt-drawer-close="#kt_engage_demos_close">

  <!--begin::Card-->
  <div class="card shadow-none rounded-0 w-100">
    <!--begin::Header-->
    <div class="card-header" id="kt_explore_header">
      <h3 class="card-title fw-bolder text-gray-700">Explore</h3>

      <div class="card-toolbar">
        <button type="button" class="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_engage_demos_close">
          <app-keenicon name="cross" class="fs-2"></app-keenicon>
        </button>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body" id="kt_explore_body">
      <!--begin::Content-->
      <div
        id="kt_explore_scroll"
        class="scroll-y me-n5 pe-5"
        data-kt-scroll="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-wrappers="#kt_explore_body"
        data-kt-scroll-dependencies="#kt_explore_header"
        data-kt-scroll-offset="5px">

        <!--begin::Wrapper-->
        <div class="mb-0">
          <!--begin::Header-->
          <div class="mb-7">
            <div class="d-flex flex-stack">
              <h3 class="mb-0">Metronic Licenses</h3>

              <a href="https://themeforest.net/licenses/standard" class="fw-bold" target="_blank">
                License FAQs
              </a>
            </div>
          </div>
          <!--end::Header-->

          <!--begin::License-->
          <div class="rounded border border-dashed border-gray-300 py-4 px-6 mb-5">
            <div class="d-flex flex-stack">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-1">
                  <div class="fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1">Regular License</div>

                  <i class="text-gray-500 fas fa-exclamation-circle ms-1 fs-7"
                     data-bs-toggle="popover"
                     data-bs-custom-class="popover-dark"
                     data-bs-trigger="hover"
                     data-bs-placement="top"
                     data-bs-content="Use, by you or one client in a single end product which end users are not charged for.">
                  </i>
                </div>
                <div class="fs-7 text-muted">For single end product used by you or one client</div>
              </div>

              <div class="text-nowrap">
                <span class="text-muted fs-7 fw-bold me-n1">$</span>
                <span class="text-gray-900 fs-1 fw-bolder">39</span>
              </div>
            </div>
          </div>
          <!--end::License-->

          <!--begin::License-->
          <div class="rounded border border-dashed border-gray-300 py-4 px-6 mb-5">
            <div class="d-flex flex-stack">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-1">
                  <div class="fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1">Extended License</div>

                  <i class="text-gray-500 fas fa-exclamation-circle ms-1 fs-7"
                     data-bs-toggle="popover"
                     data-bs-custom-class="popover-dark"
                     data-bs-trigger="hover"
                     data-bs-placement="top"
                     data-bs-content="Use, by you or one client, in a single end product which end users can be charged for.">
                  </i>
                </div>
                <div class="fs-7 text-muted">For single end product with paying users.</div>
              </div>

              <div class="text-nowrap">
                <span class="text-muted fs-7 fw-bold me-n1">$</span>
                <span class="text-gray-900 fs-1 fw-bolder">939</span>
              </div>
            </div>
          </div>
          <!--end::License-->

          <!--begin::License-->
          <div class="rounded border border-dashed border-gray-300 py-4 px-6 mb-5">
            <div class="d-flex flex-stack">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-1">
                  <div class="fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1">Custom License</div>
                </div>
                <div class="fs-7 text-muted">Reach us for custom license offers.</div>
              </div>

              <div class="text-nowrap">
                <a href="https://keenthemes.com/contact/" class="btn btn-sm btn-success" target="_blank">Contact Us</a>
              </div>
            </div>
          </div>
          <!--end::License-->

          <!--begin::Purchase-->
          <a href="{{ appPurchaseUrl }}" class="btn btn-primary mb-15 w-100">
            Buy Now
          </a>
          <!--end::Purchase-->

          <!--begin::Demos-->
          <div class="mb-0">
            <h3 class="fw-bolder text-center mb-6">{{ appThemeName }} Demos</h3>

            <!--begin::Row-->
            <div class="row g-5">
              <!--begin::Col-->
              <div class="col-6" *ngFor="let demo of appDemos | keyvalue">
                <!--begin::Demo-->
                <div class="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                  <ng-container *ngIf="demo.value.published === true; else unpublished">
                    <div class="overlay-wrapper">
                      <img src="{{ demo.value.thumbnail }}" alt="demo" class="w-100"/>
                    </div>

                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <a href="https://preview.keenthemes.com/metronic8/angular/{{ demo.key }}" class="btn btn-sm btn-success shadow">{{ demo.value.title }}</a>
                    </div>
                  </ng-container>

                  <ng-template #unpublished>
                    <div class="overlay-wrapper">
                      <img src="{{ demo.value.thumbnail }}" alt="demo" class="w-100 opacity-25"/>
                    </div>

                    <div class="overlay-layer bg-dark bg-opacity-10">
                      <div class="badge badge-white px-6 py-4 fw-bold fs-base shadow">Coming soon</div>
                    </div>
                  </ng-template>

                </div>
                <!--end::Demo-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
          <!--end::Demos-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Card-->
</div>
<!--end::Exolore drawer-->

