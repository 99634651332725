<ng-container *ngIf="appContentContiner">
  <!--begin::Content container-->
 
  <div id="kt_app_content_container" class="app-container"
    [ngClass]="appContentContainerClass + ' ' + contentContainerCSSClass"
    [ngClass]="{'container-xxl': appContentContiner === 'fixed', 'container-fluid': appContentContiner === 'fluid'}">
    @if(sharedService.loader()) {
      <mat-progress-bar mode="indeterminate" class="table-loader card-loader"></mat-progress-bar>
      }
    <router-outlet></router-outlet>
   
  </div>
  <!--end::Content container-->
</ng-container>
<ng-container *ngIf="!appContentContiner">
  @if(sharedService.loader()) {
    <mat-progress-bar mode="indeterminate" class="table-loader card-loader"></mat-progress-bar>
    }
  <router-outlet></router-outlet>
</ng-container>