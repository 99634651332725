<div class="card-body d-flex flex-column pb-10 pb-lg-15">
  <div class="flex-grow-1">
    <!-- begin::Info -->
    <div class="d-flex align-items-center pe-2 mb-5">
      <span class="text-muted fw-bolder fs-5 flex-grow-1">{{ time }}</span>

      <div class="symbol symbol-50px">
        <span class="symbol-label bg-light">
          <img [src]="image" class="h-50 align-self-center" alt="" />
        </span>
      </div>
    </div>
    <!-- end::Info -->

    <!-- begin::Link -->
    <a href="#" class="text-gray-900 fw-bolder text-hover-primary fs-4">
      {{ title }}
    </a>
    <!-- end::Link -->

    <!-- begin::Desc -->
    <p class="py-3">
      <ng-content select="description"></ng-content>
    </p>
    <!-- end::Desc -->
  </div>

  <!-- begin::Team -->
  <div class="d-flex align-items-center">
    <a
      href="#"
      class="symbol symbol-35px me-2"
      data-bs-toggle="tooltip"
      title="Ana Stone"
    >
      <img src="./assets/media/avatars/300-6.jpg" alt="" />
    </a>

    <a
      href="#"
      class="symbol symbol-35px me-2"
      data-bs-toggle="tooltip"
      title="Mark Larson"
    >
      <img src="./assets/media/avatars/300-5.jpg" alt="" />
    </a>

    <a
      href="#"
      class="symbol symbol-35px me-2"
      data-bs-toggle="tooltip"
      title="Sam Harris"
    >
      <img src="../assets/media/avatars/300-9.jpg" alt="" />
    </a>
  </div>
</div>
