<div class="card card-custom" [ngClass]="cssClass">
  <!-- Header -->
  <div class="card-header border-0 pt-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder text-gray-900"
        >Authors Earnings</span
      >
      <span class="text-muted mt-3 font-weight-bold font-size-sm"
        >More than 400+ new members</span
      >
    </h3>
    <div class="card-toolbar">
      <ul class="nav nav-pills nav-pills-sm nav-dark-75">
        <li
          class="nav-item"
          (click)="setCurrentTab(TABS[0])"
          [style.cursor]="'pointer'"
        >
          <a
            class="nav-link py-2 px-4"
            [class.active]="currentTab === TABS[0]"
            data-toggle="tab"
            >Month</a
          >
        </li>
        <li
          class="nav-item"
          (click)="setCurrentTab(TABS[1])"
          [style.cursor]="'pointer'"
        >
          <a
            class="nav-link py-2 px-4"
            [class.active]="currentTab === TABS[1]"
            data-toggle="tab"
            >Week</a
          >
        </li>
        <li
          class="nav-item"
          (click)="setCurrentTab(TABS[2])"
          [style.cursor]="'pointer'"
        >
          <a
            class="nav-link py-2 px-4"
            [class.active]="currentTab === TABS[2]"
            data-toggle="tab"
            >Day</a
          >
        </li>
      </ul>
    </div>
  </div>
  <!-- Body -->
  <div class="card-body pt-2 pb-0 mt-n3">
    <div class="tab-content mt-5" id="myTabTables6">
      <!--begin::Table-->
      <div class="tab-content mt-5" id="myTabTables6">
        <!--begin::Tap pane-->
        <div
          class="tab-pane fade"
          [class.active]="currentTab === TABS[0]"
          [class.show]="currentTab === TABS[0]"
          id="kt_tab_pane_6_1"
          role="tabpanel"
          aria-labelledby="kt_tab_pane_6_1"
        >
          <!--begin::Table-->
          <div class="table-responsive">
            <table class="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th class="p-0 w-50px"></th>
                  <th class="p-0 min-w-150px"></th>
                  <th class="p-0 min-w-120px"></th>
                  <th class="p-0 min-w-70px"></th>
                  <th class="p-0 min-w-70px"></th>
                  <th class="p-0 min-w-50px"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="
                            './assets/media/svg/avatars/018-girl-9.svg'
                          "
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Jessie Clarcson</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >HTML, CSS Coding</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $1,200,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-warning"> +52% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="
                            './assets/media/svg/avatars/047-girl-25.svg'
                          "
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Lebron Wayde</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >ReactJS Developer</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $3,400,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-danger"> -34% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="
                            './assets/media/svg/avatars/014-girl-7.svg'
                          "
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Natali Trump</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >UI/UX Designer</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $4,500,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-success"> +48% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="'./assets/media/svg/avatars/001-boy.svg'"
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Brad Simmons</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >Successful Fellas</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $2,000,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-primary"> +28% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="
                            './assets/media/svg/avatars/043-boy-18.svg'
                          "
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Kevin Leonard</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >Art Director</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $35,600,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-info"> +230% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Tap pane-->

        <!--begin::Tap pane-->
        <div
          class="tab-pane fade"
          [class.active]="currentTab === TABS[1]"
          [class.show]="currentTab === TABS[1]"
          id="kt_tab_pane_6_2"
          role="tabpanel"
          aria-labelledby="kt_tab_pane_6_2"
        >
          <!--begin::Table-->
          <div class="table-responsive">
            <table class="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th class="p-0 w-50px"></th>
                  <th class="p-0 min-w-150px"></th>
                  <th class="p-0 min-w-120px"></th>
                  <th class="p-0 min-w-70px"></th>
                  <th class="p-0 min-w-70px"></th>
                  <th class="p-0 min-w-50px"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="
                            './assets/media/svg/avatars/014-girl-7.svg'
                          "
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Natali Trump</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >UI/UX Designer</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $4,500,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-success"> +48% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="
                            './assets/media/svg/avatars/043-boy-18.svg'
                          "
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Kevin Leonard</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >Art Director</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $35,600,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-info"> +230% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="'./assets/media/svg/avatars/001-boy.svg'"
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Brad Simmons</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >Successful Fellas</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $2,000,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-primary"> +28% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="
                            './assets/media/svg/avatars/018-girl-9.svg'
                          "
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Jessie Clarcson</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >HTML, CSS Coding</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $1,200,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-warning"> +52% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="
                            './assets/media/svg/avatars/047-girl-25.svg'
                          "
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Lebron Wayde</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >ReactJS Developer</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $3,400,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-danger"> -34% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Tap pane-->

        <!--begin::Tap pane-->
        <div
          class="tab-pane fade"
          [class.active]="currentTab === TABS[2]"
          [class.show]="currentTab === TABS[2]"
          id="kt_tab_pane_6_3"
          role="tabpanel"
          aria-labelledby="kt_tab_pane_6_3"
        >
          <!--begin::Table-->
          <div class="table-responsive">
            <table class="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th class="p-0 w-50px"></th>
                  <th class="p-0 min-w-150px"></th>
                  <th class="p-0 min-w-120px"></th>
                  <th class="p-0 min-w-70px"></th>
                  <th class="p-0 min-w-70px"></th>
                  <th class="p-0 min-w-50px"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="'./assets/media/svg/avatars/001-boy.svg'"
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Brad Simmons</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >Successful Fellas</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $2,000,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-primary"> +28% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="
                            './assets/media/svg/avatars/018-girl-9.svg'
                          "
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Jessie Clarcson</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >HTML, CSS Coding</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $1,200,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-warning"> +52% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="
                            './assets/media/svg/avatars/047-girl-25.svg'
                          "
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Lebron Wayde</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >ReactJS Developer</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $3,400,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-danger"> -34% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="
                            './assets/media/svg/avatars/014-girl-7.svg'
                          "
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Natali Trump</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >UI/UX Designer</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $4,500,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-success"> +48% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <span class="symbol-label">
                        <span
                          [inlineSVG]="
                            './assets/media/svg/avatars/043-boy-18.svg'
                          "
                          class="svg-icon h-75 align-self-end"
                        ></span>
                      </span>
                    </div>
                  </td>
                  <td class="pl-0">
                    <a
                      href="#"
                      class="
                        text-gray-900
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Kevin Leonard</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >Art Director</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      class="text-muted font-weight-bold d-block font-size-sm"
                    >
                      Paid
                    </span>
                    <span
                      class="
                        text-gray-900-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      $35,600,000
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bolder text-info"> +230% </span>
                  </td>
                  <td class="text-right pr-0">
                    <a href="#" class="btn btn-icon btn-light btn-sm">
                      <app-keenicon name="arrow-right" class="fs-md text-success"></app-keenicon>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Tap pane-->
      </div>
      <!--end::Table-->
    </div>
  </div>
</div>
