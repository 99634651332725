<!--begin::Card widget 7-->
<div class="card card-flush" [ngClass]="cssClass">
  <!--begin::Header-->
  <div class="card-header pt-5">
    <!--begin::Title-->
    <div class="card-title d-flex flex-column">
      <!--begin::Amount-->
      <span class="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{{stats}}</span>
      <!--end::Amount-->

      <!--begin::Subtitle-->
      <span class="text-gray-500 pt-1 fw-semibold fs-6">{{description}}</span>
      <!--end::Subtitle-->
    </div>
    <!--end::Title-->
  </div>
  <!--end::Header-->

  <!--begin::Card body-->
  <div class="card-body d-flex flex-column justify-content-end pe-0">
    <!--begin::Title-->
    <span class="fs-6 fw-bolder text-gray-800 d-block mb-2">Today’s Heroes</span>
    <!--end::Title-->

    <!--begin::Users group-->
    <div class="symbol-group symbol-hover flex-nowrap">
      <div *ngFor="let item of items" class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip"
        [title]="item.name">
        <ng-container *ngIf="item.src">
          <img alt="Pic" [src]="item.src" />
        </ng-container>
        <ng-container *ngIf="!item.src">
          <span class="symbol-label fw-bold"
            [ngClass]="'bg-' + item.state + ' ' + 'text-inverse-' + item.state">{{item.initials}}</span>
        </ng-container>

      </div>
      <a href="#" class="symbol symbol-35px symbol-circle">
        <span class="symbol-label fs-8 fw-bold" [ngClass]="'bg-' + labelColor"
          [ngClass]="'text-' + textColor">+42</span>
      </a>
    </div>
    <!--end::Users group-->
  </div>
  <!--end::Card body-->
</div>
<!--end::Card widget 7-->
