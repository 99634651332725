import { CommonModule, DatePipe } from '@angular/common';
import { Component, inject, OnInit, ViewChild, viewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NumberOnlyDirective } from '../../../../../muybi_fornt/src/app/shared/directives/number-only.directive';
import { KeeniconComponent } from '../../_metronic/shared/keenicon/keenicon.component';
import { ModalComponent, ModalsModule, WidgetsModule } from '../../_metronic/partials';
import { SharedModule } from '../../_metronic/shared/shared.module';
import { catchError, Subscription, tap, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DeviceManagementService } from './service/device-management.service';
import { ResponseMessage } from '../../shared/interfaces/req.res.interface';
import { DateTime24HrPipe } from '../../shared/pipes/date-time-24hr.pipe';
import { Router } from '@angular/router';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { SharedService } from '../../shared/service/shared.service';

@Component({
  selector: 'app-device-management',
  standalone: true,
  imports: [
    DateTime24HrPipe,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    NumberOnlyDirective,
    WidgetsModule,
    SharedModule,
    ModalsModule,
    InfiniteScrollDirective
  ],
  templateUrl: './device-management.component.html',
  styleUrls: ['./device-management.component.scss'],
  // providers:[DateTime24HrPipe]
  providers: [DatePipe]
})
export class DeviceManagementComponent implements OnInit {
  deviceListData: any;
  totalRecord: number = 0;
  subscribeListAPI!: Subscription;
  pageNumber: number = 1;
  perPages: number = 25;
  load = true;
  route = inject(Router);
  deviceManagementService = inject(DeviceManagementService);
  sharedService = inject(SharedService);
  toastr = inject(ToastrService);
  modalOpen: boolean = false
  modalconfig: any;
  mainDeviceData: any = [];
  total_pages = 1;
  filterStaus: any=0;
  sortingStatus: any;
  @ViewChild('deviceDeletModal') deviceDeletModal!: ModalComponent
  constructor() {
    this.getDeviceList();
    this.deviceManagementService.statusFilterVal.subscribe((res: any) => {
      this.filterStaus = res;
      if (res != 0) {
        this.deviceListData = this.mainDeviceData.filter((res1: any) => { if (res1.status == res) { return res1; } })
      } else {
        this.deviceListData = this.mainDeviceData;
      }
      if (this.deviceListData.length > 1) {
        if (this.sortingStatus == 1) {
          this.sortByNameAsc();
        } else if (this.sortingStatus == 2) {
          this.sortByNameDesc();
        } else {
          this.sortByNameAsc();
        }
      }
    });
    this.deviceManagementService.sortVal.subscribe((res: any) => {
      this.sortingStatus = res;
      if (this.deviceListData.length > 1) {
        if (res == 1) {
         this.sortByNameAsc();
        } else if (res == 2) {
          this.sortByNameDesc();
        } else {
          this.sortByNameAsc();
        }
      } else {
        this.deviceListData = this.mainDeviceData;
        this.sortByNameAsc();
      }
      if (this.filterStaus != 0) {
        this.deviceListData = this.deviceListData.filter((res1: any) => { if (res1.status == this.filterStaus) { return res1; } })
      }
    })
  }
  sortByNameAsc(){
    this.deviceListData.sort((a: any, b: any) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    })
  }
  sortByNameDesc(){
    this.deviceListData.sort((a: any, b: any) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    })
  }
  ngOnInit() {
    
  }


  //#region Device List
  getDeviceList() {
    this.pageNumber = 1;
    let devicePayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      search_text: ''
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.sharedService.loader.set(true);
    this.subscribeListAPI = this.deviceManagementService.getDeviceList(devicePayload).pipe(
      catchError((error) => {
        this.toastr.error(error.error.message);
        this.sharedService.loader.set(false);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.sharedService.loader.set(false);
          this.deviceListData = res.data.data;
          if (res.data.data.length > 0) {
            this.sharedService.showToolBar.set(true);
          } else {
            this.sharedService.showToolBar.set(false);
          }
          this.mainDeviceData = res.data.data;
          this.totalRecord = res.data.pagination.total;
          this.total_pages = res.data.pagination.total_pages;
          if (this.pageNumber < this.total_pages) {
            this.pageNumber = this.pageNumber + 1;
          }
          this.sortByNameAsc();
        } else {
          this.toastr.error(res.message);
          this.sharedService.loader.set(false);
        }
      })
  }


  addDevice() {
    this.route.navigate(['device-management/add'])
  }

  editDevice(id: number) {
    this.route.navigate(['device-management/edit', id])
  }

  deleteDevice(id: number) {
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.modalconfig = {
      modalTitle: 'Delete Device',
      dismissButtonLabel: 'No',
      closeButtonLabel: 'Yes'
    }
    this.deviceDeletModal.open();
    this.subscribeListAPI = this.deviceDeletModal?.modalRef?.closed.subscribe((res: any) => {
      this.sharedService.loader.set(true);
      if (res) {
        this.deviceManagementService.deleteDevice(id).pipe(tap((res: any) => {
          if (res.code == 200) {
            this.toastr.success(res.message);
            this.sharedService.loader.set(false);
            this.getDeviceList();
          } else {
            this.toastr.error(res.message);
            this.sharedService.loader.set(false);
          }
        }),
          catchError((err) => {
            this.toastr.error(err.error.message);
            this.sharedService.loader.set(false);
            return throwError(() => err.error.message);
          })
        ).subscribe();
      }
    })
  }
  searchDevice(event: any) {
    if (event.target.value) {
      let statusFlag: any
      if (event.target.value.toLowerCase() == 'active') { statusFlag = 1 };
      if (event.target.value.toLowerCase() == 'inactive') { statusFlag = 2 };
      this.deviceListData = this.mainDeviceData.filter((res: any) => { if (res?.name?.toLowerCase().includes(event?.target?.value?.toLowerCase()) || res?.battery?.toLowerCase()?.includes(event?.target?.value?.toLowerCase()) || res?.name?.toLowerCase()?.includes(event?.target?.value?.toLowerCase()) || (res.status === statusFlag) || res?.street_name?.toLowerCase()?.includes(event?.target?.value?.toLowerCase())) { return res } })
    } else {
      this.deviceListData = this.mainDeviceData
    }
  }
  getScrolledDown() {
    let devicePayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      search_text: ''
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.sharedService.loader.set(true);
    this.subscribeListAPI = this.deviceManagementService.getDeviceList(devicePayload).pipe(
      catchError((error) => {
        this.sharedService.loader.set(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.sharedService.loader.set(false);
          if (this.pageNumber <= this.total_pages) {
            this.deviceListData.push(res.data.data);
            this.mainDeviceData.push(res.data.data);
            this.totalRecord = res.data.pagination.total;
            this.total_pages = res.data.pagination.total_pages;

            this.pageNumber = this.pageNumber + 1;
          }
        } else {
          this.toastr.error(res.message);
          this.sharedService.loader.set(false);
        }
      })
  }
  ngOnDestroy() {
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
  }
}
