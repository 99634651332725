<!-- begin::Header -->
<div class="card-header border-0 pt-5">
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder text-gray-900">Tasks Overview</span>
    <span class="text-muted mt-1 fw-bold fs-7">Pending 10 tasks</span>
  </h3>

  <div class="card-toolbar">
    <!-- begin::Menu -->
    <button
      type="button"
      class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <app-keenicon name="category" class="fs-2"></app-keenicon>
    </button>
    <app-dropdown-menu1></app-dropdown-menu1>
    <!-- end::Menu -->
  </div>
</div>
<!-- end::Header -->

<!-- begin::Body -->
<div class="card-body pt-5">
  <!-- begin::Item -->
  <div class="d-flex align-items-center mb-7">
    <!-- begin::Symbol -->
    <div class="symbol symbol-50px me-5">
      <span class="symbol-label bg-light-success">
        <app-keenicon name="abstract-26" class="fs-2x text-success"></app-keenicon>
      </span>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Text -->
    <div class="d-flex flex-column">
      <a href="#" class="text-gray-900 text-hover-primary fs-6 fw-bolder">
        Project Briefing
      </a>
      <span class="text-muted fw-bold">Project Manager</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center mb-7">
    <!-- begin::Symbol -->
    <div class="symbol symbol-50px me-5">
      <span class="symbol-label bg-light-warning">
        <app-keenicon name="pencil" class="fs-2x text-warning"></app-keenicon>
      </span>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Text -->
    <div class="d-flex flex-column">
      <a href="#" class="text-gray-900 text-hover-primary fs-6 fw-bolder">
        Concept Design
      </a>
      <span class="text-muted fw-bold">Art Director</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center mb-7">
    <!-- begin::Symbol -->
    <div class="symbol symbol-50px me-5">
      <span class="symbol-label bg-light-primary">
        <app-keenicon name="element-plus" class="fs-2x text-primary"></app-keenicon>
      </span>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Text -->
    <div class="d-flex flex-column">
      <a href="#" class="text-gray-900 text-hover-primary fs-6 fw-bolder">
        Functional Logics
      </a>
      <span class="text-muted fw-bold">Lead Developer</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center mb-7">
    <!-- begin::Symbol -->
    <div class="symbol symbol-50px me-5">
      <span class="symbol-label bg-light-danger">
        <app-keenicon name="disconnect" class="fs-2x text-danger"></app-keenicon>
      </span>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Text -->
    <div class="d-flex flex-column">
      <a href="#" class="text-gray-900 text-hover-primary fs-6 fw-bolder">
        Development
      </a>
      <span class="text-muted fw-bold">DevOps</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center">
    <!-- begin::Symbol -->
    <div class="symbol symbol-50px me-5">
      <span class="symbol-label bg-light-info">
        <app-keenicon name="security-user" class="fs-2x text-info"></app-keenicon>
      </span>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Text -->
    <div class="d-flex flex-column">
      <a href="#" class="text-gray-900 text-hover-primary fs-6 fw-bolder">
        Testing
      </a>
      <span class="text-muted fw-bold">QA Managers</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
</div>
<!-- end::Body -->
