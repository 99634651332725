import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutType } from '../../../core/configs/config';
import { LayoutInitService } from '../../../core/layout-init.service';
import { LayoutService } from '../../../core/layout.service';
import { ResponseMessage } from '../../../../../shared/interfaces/req.res.interface';
import { SharedService } from '../../../../../shared/service/shared.service';
import { catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  constructor(private router: Router, private layout: LayoutService, private layoutInit: LayoutInitService, private sharedService: SharedService, private toastr: ToastrService) { }
  selectedLocation: any = '';
  locationList: any = [];
  ngOnInit(): void {
    this.getBranchLocations();
    this.selectedLocation = localStorage.getItem('selected_location_id') ? localStorage.getItem('selected_location_id') : ''
  }
  selectLocation() {
    localStorage.setItem('selected_location_id', this.selectedLocation);
  }
  getBranchLocations() {
    this.layout.getBranchLocations().subscribe((res: ResponseMessage) => {
      if (res.code == 200) {
        this.locationList = res.data.data;
          if(!this.selectedLocation){
            this.selectedLocation = this.locationList[0].business_location_id;
            this.selectLocation();
          }      
      }
    })
  }
  calculateMenuItemCssClass(url: string): string {
    return checkIsActive(this.router.url, url) ? 'active' : '';
  }

  setBaseLayoutType(layoutType: LayoutType) {
    this.layoutInit.setBaseLayoutType(layoutType);
  }

  setToolbar(toolbarLayout: 'classic' | 'accounting' | 'extended' | 'reports' | 'saas') {
    const currentConfig = { ...this.layout.layoutConfigSubject.value };
    if (currentConfig && currentConfig.app && currentConfig.app.toolbar) {
      currentConfig.app.toolbar.layout = toolbarLayout;
      this.layout.saveBaseConfig(currentConfig)
    }
  }

  ngOnDestroy() {
    // localStorage.removeItem('selected_location_id');
  }
}

const getCurrentUrl = (pathname: string): string => {
  return pathname.split(/[?#]/)[0];
};

const checkIsActive = (pathname: string, url: string) => {
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
};
