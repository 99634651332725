<!-- begin::Header -->
<div class="card-header border-0 pt-5">
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1">Latest Products</span>
    <span class="text-muted mt-1 fw-bold fs-7">More than 100 new orders</span>
  </h3>
  <div class="card-toolbar">
    <ul class="nav">
      <li class="nav-item">
        <a
          class="
            nav-link
            btn btn-sm btn-color-muted btn-active btn-active-light-primary
            fw-bolder
            px-4
            me-1
          "
          data-bs-toggle="tab"
          [ngClass]="activeClass('kt_table_widget_8_tab_1')"
          (click)="setTab('kt_table_widget_8_tab_1')"
        >
          Month
        </a>
      </li>
      <li class="nav-item">
        <a
          class="
            nav-link
            btn btn-sm btn-color-muted btn-active btn-active-light-primary
            fw-bolder
            px-4
            me-1
          "
          data-bs-toggle="tab"
          [ngClass]="activeClass('kt_table_widget_8_tab_2')"
          (click)="setTab('kt_table_widget_8_tab_2')"
        >
          Week
        </a>
      </li>
      <li class="nav-item">
        <a
          class="
            nav-link
            btn btn-sm btn-color-muted btn-active btn-active-light-primary
            fw-bolder
            px-4
          "
          data-bs-toggle="tab"
          [ngClass]="activeClass('kt_table_widget_8_tab_3')"
          (click)="setTab('kt_table_widget_8_tab_3')"
        >
          Day
        </a>
      </li>
    </ul>
  </div>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body py-3">
  <div class="tab-content">
    <!-- begin::Tap pane -->
    <div
      class="tab-pane fade"
      [ngClass]="activeClass('kt_table_widget_8_tab_1')"
      id="kt_table_widget_8_tab_1"
    >
      <!-- begin::Table container -->
      <div class="table-responsive">
        <!-- begin::Table -->
        <table class="table align-middle gs-0 gy-3">
          <!-- begin::Table head -->
          <thead>
            <tr>
              <th class="p-0 w-50px"></th>
              <th class="p-0 min-w-150px"></th>
              <th class="p-0 min-w-120px"></th>
            </tr>
          </thead>
          <!-- end::Table head -->
          <!-- begin::Table body -->
          <tbody>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-danger">
                    <app-keenicon name="scroll" class="fs-2x text-danger"></app-keenicon>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-gray-900 fw-bolder text-hover-primary mb-1 fs-6"
                >
                  School Music Festival
                </a>
                <span class="text-muted fw-bold d-block fs-7"
                  >by Rose Liam</span
                >
              </td>
              <td class="text-end">
                <span class="text-gray-900 fw-bolder d-block fs-7"
                  >4:20PM, 03 Sep</span
                >
                <span class="text-muted fw-bold d-block fs-8">Date</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-warning">
                    <app-keenicon name="category" class="fs-2x text-warning"></app-keenicon>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-gray-900 fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Maths Championship
                </a>
                <span class="text-muted fw-bold d-block fs-7">By Tom Gere</span>
              </td>
              <td class="text-end">
                <span class="text-gray-900 fw-bolder d-block fs-7"
                  >10:05PM, 25 Oct</span
                >
                <span class="text-muted fw-bold d-block fs-8">Date</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-info">
                    <app-keenicon name="document" class="fs-2x text-info"></app-keenicon>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-gray-900 fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Who Knows Geography
                </a>
                <span class="text-muted fw-bold d-block fs-7"
                  >By Zoey Dylan</span
                >
              </td>
              <td class="text-end">
                <span class="text-gray-900 fw-bolder d-block fs-7"
                  >3:22PM, 07 Sep</span
                >
                <span class="text-muted fw-bold d-block fs-8">Date</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-primary">
                    <app-keenicon name="abstract-26" class="fs-2x text-primary"></app-keenicon>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-gray-900 fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Napoleon Days
                </a>
                <span class="text-muted fw-bold d-block fs-7"
                  >By Luke Owen</span
                >
              </td>
              <td class="text-end">
                <span class="text-gray-900 fw-bolder d-block fs-7"
                  >1:20PM, 02 Dec</span
                >
                <span class="text-muted fw-bold d-block fs-8">Date</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-success">
                    <app-keenicon name="bucket" class="fs-2x text-success"></app-keenicon>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-gray-900 fw-bolder text-hover-primary mb-1 fs-6"
                >
                  The School Art Leads
                </a>
                <span class="text-muted fw-bold d-block fs-7"
                  >By Ellie Cole</span
                >
              </td>
              <td class="text-end">
                <span class="text-gray-900 fw-bolder d-block fs-7"
                  >6:20PM, 07 Sep</span
                >
                <span class="text-muted fw-bold d-block fs-8">Date</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <!-- end::Table body -->
        </table>
      </div>
      <!-- end::Table -->
    </div>
    <!-- end::Tap pane -->
    <!-- begin::Tap pane -->
    <div
      class="tab-pane fade"
      [ngClass]="activeClass('kt_table_widget_8_tab_2')"
      id="kt_table_widget_8_tab_2"
    >
      <!-- begin::Table container -->
      <div class="table-responsive">
        <!-- begin::Table -->
        <table class="table align-middle gs-0 gy-3">
          <!-- begin::Table head -->
          <thead>
            <tr>
              <th class="p-0 w-50px"></th>
              <th class="p-0 min-w-150px"></th>
              <th class="p-0 min-w-120px"></th>
            </tr>
          </thead>
          <!-- end::Table head -->
          <!-- begin::Table body -->
          <tbody>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-info">
                    <app-keenicon name="document" class="fs-2x text-info"></app-keenicon>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-gray-900 fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Who Knows Geography
                </a>
                <span class="text-muted fw-bold d-block fs-7"
                  >By Zoey Dylan</span
                >
              </td>
              <td class="text-end">
                <span class="text-gray-900 fw-bolder d-block fs-7"
                  >3:22PM, 07 Sep</span
                >
                <span class="text-muted fw-bold d-block fs-8">Date</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-warning">
                    <app-keenicon name="category" class="fs-2x text-warning"></app-keenicon>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-gray-900 fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Maths Championship
                </a>
                <span class="text-muted fw-bold d-block fs-7">By Tom Gere</span>
              </td>
              <td class="text-end">
                <span class="text-gray-900 fw-bolder d-block fs-7"
                  >10:05PM, 25 Oct</span
                >
                <span class="text-muted fw-bold d-block fs-8">Date</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-danger">
                    <app-keenicon name="scroll" class="fs-2x text-danger"></app-keenicon>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-gray-900 fw-bolder text-hover-primary mb-1 fs-6"
                >
                  School Music Festival
                </a>
                <span class="text-muted fw-bold d-block fs-7"
                  >by Rose Liam</span
                >
              </td>
              <td class="text-end">
                <span class="text-gray-900 fw-bolder d-block fs-7"
                  >4:20PM, 03 Sep</span
                >
                <span class="text-muted fw-bold d-block fs-8">Date</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <!-- end::Table body -->
        </table>
      </div>
      <!-- end::Table -->
    </div>
    <!-- end::Tap pane -->
    <!-- begin::Tap pane -->
    <div
      class="tab-pane fade"
      [ngClass]="activeClass('kt_table_widget_8_tab_3')"
      id="kt_table_widget_8_tab_3"
    >
      <!-- begin::Table container -->
      <div class="table-responsive">
        <!-- begin::Table -->
        <table class="table align-middle gs-0 gy-3">
          <!-- begin::Table head -->
          <thead>
            <tr>
              <th class="p-0 w-50px"></th>
              <th class="p-0 min-w-150px"></th>
              <th class="p-0 min-w-120px"></th>
            </tr>
          </thead>
          <!-- end::Table head -->
          <!-- begin::Table body -->
          <tbody>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-warning">
                    <app-keenicon name="category" class="fs-2x text-warning"></app-keenicon>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-gray-900 fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Maths Championship
                </a>
                <span class="text-muted fw-bold d-block fs-7">By Tom Gere</span>
              </td>
              <td class="text-end">
                <span class="text-gray-900 fw-bolder d-block fs-7"
                  >10:05PM, 25 Oct</span
                >
                <span class="text-muted fw-bold d-block fs-8">Date</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-info">
                    <app-keenicon name="document" class="fs-2x text-info"></app-keenicon>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-gray-900 fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Who Knows Geography
                </a>
                <span class="text-muted fw-bold d-block fs-7"
                  >By Zoey Dylan</span
                >
              </td>
              <td class="text-end">
                <span class="text-gray-900 fw-bolder d-block fs-7"
                  >3:22PM, 07 Sep</span
                >
                <span class="text-muted fw-bold d-block fs-8">Date</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-primary">
                    <app-keenicon name="document" class="fs-2x text-primary"></app-keenicon>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-gray-900 fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Napoleon Days
                </a>
                <span class="text-muted fw-bold d-block fs-7"
                  >By Luke Owen</span
                >
              </td>
              <td class="text-end">
                <span class="text-gray-900 fw-bolder d-block fs-7"
                  >1:20PM, 02 Dec</span
                >
                <span class="text-muted fw-bold d-block fs-8">Date</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-danger">
                    <app-keenicon name="scroll" class="fs-2x text-danger"></app-keenicon>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-gray-900 fw-bolder text-hover-primary mb-1 fs-6"
                >
                  School Music Festival
                </a>
                <span class="text-muted fw-bold d-block fs-7"
                  >by Rose Liam</span
                >
              </td>
              <td class="text-end">
                <span class="text-gray-900 fw-bolder d-block fs-7"
                  >4:20PM, 03 Sep</span
                >
                <span class="text-muted fw-bold d-block fs-8">Date</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <!-- end::Table body -->
        </table>
      </div>
      <!-- end::Table -->
    </div>
    <!-- end::Tap pane -->
  </div>
</div>
<!-- end::Body -->
