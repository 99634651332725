import { Routes } from '@angular/router';
import { DeviceManagementComponent } from '../modules/device-management/device-management.component';


const Routing: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('../modules/vendor-profile/vendor-profile.module').then((m) => m.VendorProfileModule),
  },
  {
    path: 'vendor-management',
    loadChildren: () => import('../modules/vendor-management/vendor-mangement.module').then((m) => m.VendorManagementModule),
  },
  {
    path: 'device-management',
    component: DeviceManagementComponent,
  },
  {
    path: 'device-management/add',
    loadComponent: () => import('../modules/device-management/add-device/add-device.component').then((m) => m.AddDeviceComponent)
  },
  {
    path: 'device-management/edit/:id',
    loadComponent: () => import('../modules/device-management/add-device/add-device.component').then((m) => m.AddDeviceComponent)
  },
  {
    path: 'coupon-management',
    loadChildren: () => import('../modules/coupon-management/coupon-management.module').then((m) => m.CouponManagementModule)
  },
  {
    path:'user-role-management',
    loadChildren:() => import('../modules/user-role-management/user-role-management.module').then((m)=> m.UserRoleManagementModule)
  },
  // {
  //   path: 'builder',
  //   loadChildren: () => import('./builder/builder.module').then((m) => m.BuilderModule),
  // },
  // {
  //   path: 'crafted/pages/profile',
  //   loadChildren: () => import('../modules/profile/profile.module').then((m) => m.ProfileModule),
  //   // data: { layout: 'light-sidebar' },
  // },
  // {
  //   path: 'crafted/account',
  //   loadChildren: () => import('../modules/account/account.module').then((m) => m.AccountModule),
  //   // data: { layout: 'dark-header' },
  // },
  // {
  //   path: 'crafted/pages/wizards',
  //   loadChildren: () => import('../modules/wizards/wizards.module').then((m) => m.WizardsModule),
  //   // data: { layout: 'light-header' },
  // },
  // {
  //   path: 'crafted/widgets',
  //   loadChildren: () => import('../modules/widgets-examples/widgets-examples.module').then((m) => m.WidgetsExamplesModule),
  //   // data: { layout: 'light-header' },
  // },
  // {
  //   path: 'apps/chat',
  //   loadChildren: () => import('../modules/apps/chat/chat.module').then((m) => m.ChatModule),
  //   // data: { layout: 'light-sidebar' },
  // },
  // {
  //   path: 'apps/users',
  //   loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  // },
  // {
  //   path: 'apps/roles',
  //   loadChildren: () => import('./role/role.module').then((m) => m.RoleModule),
  // },
  // {
  //   path: 'apps/permissions',
  //   loadChildren: () => import('./permission/permission.module').then((m) => m.PermissionModule),
  // },

  {
    path: '**',
    redirectTo: 'error/404',
  },
];

export { Routing };
