<!-- begin::Header-->
<div class="card-header border-0 pt-5">
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder text-gray-900">Trends</span>
    <span class="text-muted mt-1 fw-bold fs-7">Latest tech trends</span>
  </h3>
  <div class="card-toolbar">
    <!-- begin::Menu-->
    <button
      type="button"
      class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <app-keenicon name="category" class="fs-2"></app-keenicon>
    </button>
    <app-dropdown-menu1></app-dropdown-menu1>
    <!-- end::Menu-->
  </div>
</div>
<!-- end::Header-->
<!-- begin::Body-->
<div class="card-body pt-5">
  <!-- begin::Item-->
  <div class="d-flex align-items-sm-center mb-7">
    <!-- begin::Symbol-->
    <div class="symbol symbol-50px me-5">
      <span class="symbol-label">
        <img
          src="./assets/media/svg/brand-logos/plurk.svg"
          class="h-50 align-self-center"
          alt=""
        />
      </span>
    </div>
    <!-- end::Symbol-->
    <!-- begin::Section-->
    <div class="d-flex align-items-center flex-row-fluid flex-wrap">
      <div class="flex-grow-1 me-2">
        <a href="#" class="text-gray-800 text-hover-primary fs-6 fw-bolder">
          Top Authors
        </a>
        <span class="text-muted fw-bold d-block fs-7"
          >Mark, Rowling, Esther</span
        >
      </div>
      <span class="badge badge-light fw-bolder my-2">+82$</span>
    </div>
    <!-- end::Section-->
  </div>
  <!-- end::Item-->
  <!-- begin::Item-->
  <div class="d-flex align-items-sm-center mb-7">
    <!-- begin::Symbol-->
    <div class="symbol symbol-50px me-5">
      <span class="symbol-label">
        <img
          src="./assets/media/svg/brand-logos/telegram.svg"
          class="h-50 align-self-center"
          alt=""
        />
      </span>
    </div>
    <!-- end::Symbol-->
    <!-- begin::Section-->
    <div class="d-flex align-items-center flex-row-fluid flex-wrap">
      <div class="flex-grow-1 me-2">
        <a href="#" class="text-gray-800 text-hover-primary fs-6 fw-bolder">
          Popular Authors
        </a>
        <span class="text-muted fw-bold d-block fs-7">Randy, Steve, Mike</span>
      </div>
      <span class="badge badge-light fw-bolder my-2">+280$</span>
    </div>
    <!-- end::Section-->
  </div>
  <!-- end::Item-->
  <!-- begin::Item-->
  <div class="d-flex align-items-sm-center mb-7">
    <!-- begin::Symbol-->
    <div class="symbol symbol-50px me-5">
      <span class="symbol-label">
        <img
          src="./assets/media/svg/brand-logos/vimeo.svg"
          class="h-50 align-self-center"
          alt=""
        />
      </span>
    </div>
    <!-- end::Symbol-->
    <!-- begin::Section-->
    <div class="d-flex align-items-center flex-row-fluid flex-wrap">
      <div class="flex-grow-1 me-2">
        <a href="#" class="text-gray-800 text-hover-primary fs-6 fw-bolder">
          New Users
        </a>
        <span class="text-muted fw-bold d-block fs-7">John, Pat, Jimmy</span>
      </div>
      <span class="badge badge-light fw-bolder my-2">+4500$</span>
    </div>
    <!-- end::Section-->
  </div>
  <!-- end::Item-->
  <!-- begin::Item-->
  <div class="d-flex align-items-sm-center mb-7">
    <!-- begin::Symbol-->
    <div class="symbol symbol-50px me-5">
      <span class="symbol-label">
        <img
          src="./assets/media/svg/brand-logos/bebo.svg"
          class="h-50 align-self-center"
          alt=""
        />
      </span>
    </div>
    <!-- end::Symbol-->
    <!-- begin::Section-->
    <div class="d-flex align-items-center flex-row-fluid flex-wrap">
      <div class="flex-grow-1 me-2">
        <a href="#" class="text-gray-800 text-hover-primary fs-6 fw-bolder">
          Active Customers
        </a>
        <span class="text-muted fw-bold d-block fs-7"
          >Mark, Rowling, Esther</span
        >
      </div>
      <span class="badge badge-light fw-bolder my-2">+4500$</span>
    </div>
    <!-- end::Section-->
  </div>
  <!-- end::Item-->

  <ng-container *ngIf="items > 4">
    <div class="d-flex align-items-sm-center mb-7">
      <!-- begin::Symbol-->
      <div class="symbol symbol-50px me-5">
        <span class="symbol-label">
          <img
            src="./assets/media/svg/brand-logos/kickstarter.svg"
            class="h-50 align-self-center"
            alt=""
          />
        </span>
      </div>
      <!-- end::Symbol-->
      <!-- begin::Section-->
      <div class="d-flex align-items-center flex-row-fluid flex-wrap">
        <div class="flex-grow-1 me-2">
          <a href="#" class="text-gray-800 text-hover-primary fs-6 fw-bolder">
            Bestseller Theme
          </a>
          <span class="text-muted fw-bold d-block fs-7"
            >Disco, Retro, Sports</span
          >
        </div>
        <span class="badge badge-light fw-bolder my-2">+4500$</span>
      </div>
      <!-- end::Section-->
    </div>
  </ng-container>

  <ng-container *ngIf="items > 5">
    <div class="d-flex align-items-sm-center">
      <!-- begin::Symbol-->
      <div class="symbol symbol-50px me-5">
        <span class="symbol-label">
          <img
            src="./assets/media/svg/brand-logos/fox-hub.svg"
            class="h-50 align-self-center"
            alt=""
          />
        </span>
      </div>
      <!-- end::Symbol-->
      <!-- begin::Section-->
      <div class="d-flex align-items-center flex-row-fluid flex-wrap">
        <div class="flex-grow-1 me-2">
          <a href="#" class="text-gray-800 text-hover-primary fs-6 fw-bolder">
            Fox Broker App
          </a>
          <span class="text-muted fw-bold d-block fs-7"
            >Finance, Corporate, Apps</span
          >
        </div>
        <span class="badge badge-light fw-bolder my-2">+4500$</span>
      </div>
      <!-- end::Section-->
    </div>
  </ng-container>
</div>
<!-- end::Body-->
