import { Component, Input, OnInit } from '@angular/core';
import { DeviceManagementService } from '../../../../../modules/device-management/service/device-management.service';
import { Router } from '@angular/router';
import { SharedService } from '../../../../../shared/service/shared.service';
import { CoupontManagementService } from '../../../../../modules/coupon-management/services/coupont-management.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  @Input() appPageTitleDisplay!: boolean;

  constructor(private deviceManagementService: DeviceManagementService,public route:Router,public sharedService:SharedService,private couponManagementService:CoupontManagementService) { }

  ngOnInit(): void { }

  changeFilter(event: any) {
    this.deviceManagementService.statusFilterVal.next(event.target.value);
  }
  sortDevice(event: any) {
    this.deviceManagementService.sortVal.next(event.target.value);
  }
  filterCoupon(event:any){
    this.couponManagementService.couponFilter.next(event.target.value);
  }
  filterCouponType(event:any){
    this.couponManagementService.couponTypeFilter.next(event.target.value);
  }
}
