<!--begin::Card widget 18-->
<div class="card card-flush" [ngClass]="cssClass">
  <!--begin::Body-->
  <div class="card-body py-9">
    <!--begin::Row-->
    <div class="row gx-9 h-100">
      <!--begin::Col-->
      <div class="col-sm-6 mb-10 mb-sm-0">
        <!--begin::Image-->
        <div class="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-400px min-h-sm-100 h-100"
          [ngStyle]="{'background-size': '100% 100%', 'background-image': 'url(' + image + ')'}">
        </div>
        <!--end::Image-->
      </div>
      <!--end::Col-->

      <!--begin::Col-->
      <div class="col-sm-6">
        <!--begin::Wrapper-->
        <div class="d-flex flex-column h-100">
          <!--begin::Header-->
          <div class="mb-7">
            <!--begin::Headin-->
            <div class="d-flex flex-stack mb-6">
              <!--begin::Title-->
              <div class="flex-shrink-0 me-5">
                <span class="text-gray-500 fs-7 fw-bold me-2 d-block lh-1 pb-1">Featured</span>

                <span class="text-gray-800 fs-1 fw-bold">9 Degree</span>
              </div>
              <!--end::Title-->

              <span class="badge badge-light-primary flex-shrink-0 align-self-center py-3 px-4 fs-7">In Process</span>
            </div>
            <!--end::Heading-->

            <!--begin::Items-->
            <div class="d-flex align-items-center flex-wrap d-grid gap-2">
              <!--begin::Item-->
              <div class="d-flex align-items-center me-5 me-xl-13">
                <!--begin::Symbol-->
                <div class="symbol symbol-30px symbol-circle me-3">
                  <img src="./assets/media/avatars/300-3.jpg" class="" alt="" />
                </div>
                <!--end::Symbol-->

                <!--begin::Info-->
                <div class="m-0">
                  <span class="fw-semibold text-gray-500 d-block fs-8">Manager</span>
                  <a href="#" class="fw-bold text-gray-800 text-hover-primary fs-7">Robert Fox</a>
                </div>
                <!--end::Info-->
              </div>
              <!--end::Item-->

              <!--begin::Item-->
              <div class="d-flex align-items-center">
                <!--begin::Symbol-->
                <div class="symbol symbol-30px symbol-circle me-3">
                  <span class="symbol-label bg-success">
                    <app-keenicon name="abstract-41" class="fs-5 text-white"></app-keenicon>
                  </span>
                </div>
                <!--end::Symbol-->

                <!--begin::Info-->
                <div class="m-0">
                  <span class="fw-semibold text-gray-500 d-block fs-8">Budget</span>
                  <span class="fw-bold text-gray-800 fs-7">$64.800</span>
                </div>
                <!--end::Info-->
              </div>
              <!--end::Item-->
            </div>
            <!--end::Items-->
          </div>
          <!--end::Header-->

          <!--begin::Body-->
          <div class="mb-6">
            <!--begin::Text-->
            <span class="fw-semibold text-gray-600 fs-6 mb-8 d-block">
              Flat cartoony illustrations with vivid
              unblended colors and asymmetrical beautiful purple hair lady
            </span>
            <!--end::Text-->

            <!--begin::Stats-->
            <div class="d-flex">
              <!--begin::Stat-->
              <div class="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6 mb-3">
                <!--begin::Date-->
                <span class="fs-6 text-gray-700 fw-bold">Feb 6, 2021 </span>
                <!--end::Date-->

                <!--begin::Label-->
                <div class="fw-semibold text-gray-500">Due Date</div>
                <!--end::Label-->
              </div>
              <!--end::Stat-->

              <!--begin::Stat-->
              <div class="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 mb-3">
                <!--begin::Number-->
                <span class="fs-6 text-gray-700 fw-bold">$<span class="ms-n1" data-kt-countup="true"
                    data-kt-countup-value="284,900.00">0</span></span>
                <!--end::Number-->

                <!--begin::Label-->
                <div class="fw-semibold text-gray-500">Budget</div>
                <!--end::Label-->
              </div>
              <!--end::Stat-->
            </div>
            <!--end::Stats-->
          </div>
          <!--end::Body-->

          <!--begin::Footer-->
          <div class="d-flex flex-stack mt-auto bd-highlight">
            <!--begin::Users group-->
            <div class="symbol-group symbol-hover flex-nowrap">
              <div *ngFor="let card of cards" class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip"
                [title]="card.name">
                <ng-container *ngIf="card.src">
                  <img alt="Pic" [src]="card.src" />
                </ng-container>
                <ng-container *ngIf="!card.src">
                  <span class="symbol-label fw-bold"
                    [ngClass]="'bg-' + card.state + ' ' + 'text-inverse-' + card.state">{{card.initials}}</span>

                </ng-container>
              </div>
            </div>
            <!--end::Users group-->

            <!--begin::Actions-->
            <a href="#" class="text-primary opacity-75-hover fs-6 fw-semibold">
              View Project
              <app-keenicon name="arr095.svg" class="fs-4 text-gray800 ms-1"></app-keenicon>
            </a>
            <!--end::Actions-->
          </div>
          <!--end::Footer-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->
  </div>
  <!--end::Body-->
</div>
<!--end::Card widget 18-->
