<!--begin::Tiles Widget 3-->
<div
  class="card card-custom bgi-no-repeat bgi-no-repeat bgi-size-cover"
  [ngClass]="cssClass"
  [ngStyle]="{ height: widgetHeight }"
  style="background-image: url('./assets/media/misc/bg-2.jpg')"
>
  <!--begin::Body-->
  <div class="card-body d-flex flex-column">
    <!--begin::Title-->
    <a
      class="
        text-gray-900-75 text-hover-primary
        font-weight-bolder font-size-h3
        cursor-pointer
      "
      >Properties</a
    >
    <!--end::Title-->
  </div>
  <!--end::Body-->
</div>
<!--end::Tiles Widget 3-->
