<div
  class="d-flex flex-column bgi-no-repeat rounded-top"
  [style.background-image]="'url(./assets/media/misc/menu-header-bg.jpg)'"
>
  <h3 class="text-white fw-bold px-9 mt-10 mb-6">
    Notifications <span class="fs-8 opacity-75 ps-3">24 reports</span>
  </h3>

  <ul class="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">
    <li class ="nav-item">
      <a
        class="
          nav-link
          text-white
          opacity-75 opacity-state-100
          pb-4
          cursor-pointer
        "
        data-bs-toggle="tab"
        [ngClass]="activeTabId === 'kt_topbar_notifications_1' ? 'active' : ''"
        (click)="setActiveTabId('kt_topbar_notifications_1')"
      >
        Alerts
      </a>
    </li>

    <li class="nav-item">
      <a
        class="
          nav-link
          text-white
          opacity-75 opacity-state-100
          pb-4
          cursor-pointer
        "
        data-bs-toggle="tab"
        [ngClass]="activeTabId === 'kt_topbar_notifications_2' ? 'active' : ''"
        (click)="setActiveTabId('kt_topbar_notifications_2')"
      >
        Updates
      </a>
    </li>

    <li class="nav-item">
      <a
        class="
          nav-link
          text-white
          opacity-75 opacity-state-100
          pb-4
          cursor-pointer
        "
        data-bs-toggle="tab"
        [ngClass]="activeTabId === 'kt_topbar_notifications_3' ? 'active' : ''"
        (click)="setActiveTabId('kt_topbar_notifications_3')"
      >
        Logs
      </a>
    </li>
  </ul>
</div>

<div class="tab-content">
  <div
    class="tab-pane fade"
    id="kt_topbar_notifications_1"
    role="tabpanel"
    [ngClass]="activeTabId === 'kt_topbar_notifications_1' ? 'show active' : ''"
  >
    <div class="scroll-y mh-325px my-5 px-8">
      <div *ngFor="let alert of alerts" class="d-flex flex-stack py-4">
        <div class="d-flex align-items-center">
          <div class="symbol symbol-35px me-4">
            <span class="symbol-label" [ngClass]="'bg-light-' + alert.state">
              <span
                [inlineSVG]="'./assets/media/' + alert.icon"
                class="svg-icon fs-2"
                [ngClass]="'svg-icon-' + alert.state"
              ></span>
            </span>
          </div>

          <div class="mb-0 me-2">
            <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bolder">
              {{ alert.title }}
            </a>
            <div class="text-gray-500 fs-7">{{ alert.description }}</div>
          </div>
        </div>

        <span class="badge badge-light fs-8">{{ alert.time }}</span>
      </div>
    </div>

    <div class="py-3 text-center border-top">
      <a
        routerLink="/crafted/pages/profile"
        class="btn btn-color-gray-600 btn-active-color-primary"
      >
        View All
        <app-keenicon name="arrow-right" class="fs-5"></app-keenicon>
      </a>
    </div>
  </div>

  <div
    class="tab-pane fade"
    id="kt_topbar_notifications_2"
    role="tabpanel"
    [ngClass]="activeTabId === 'kt_topbar_notifications_2' ? 'show active' : ''"
  >
    <div class="d-flex flex-column px-9">
      <div class="pt-10 pb-0">
        <h3 class="text-gray-900 text-center fw-bolder">Get Pro Access</h3>

        <div class="text-center text-gray-600 fw-bold pt-1">
          Outlines keep you honest. They stoping you from amazing poorly about
          drive
        </div>

        <div class="text-center mt-5 mb-9">
          <a
            class="btn btn-sm btn-primary px-6 cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_upgrade_plan"
          >
            Upgrade
          </a>
        </div>
      </div>

      <div class="text-center px-4">
        <img
          class="mw-100 mh-200px"
          alt="metronic"
          src="./assets/media/illustrations/sketchy-1/1.png"
        />
      </div>
    </div>
  </div>

  <div
    class="tab-pane fade"
    id="kt_topbar_notifications_3"
    role="tabpanel"
    [ngClass]="activeTabId === 'kt_topbar_notifications_3' ? 'show active' : ''"
  >
    <div class="scroll-y mh-325px my-5 px-8">
      <div *ngFor="let log of logs" class="d-flex flex-stack py-4">
        <div class="d-flex align-items-center me-2">
          <span
            class="w-70px badge"
            [ngClass]="'badge-light-' + log.state + ' me-4'"
          >
            {{ log.code }}
          </span>

          <a class="text-gray-800 text-hover-primary fw-bold cursor-pointer">
            {{ log.message }}
          </a>

          <span class="badge badge-light fs-8">{{ log.time }}</span>
        </div>
      </div>
    </div>
    <div class="py-3 text-center border-top">
      <a
        routerLink="/crafted/pages/profile"
        class="btn btn-color-gray-600 btn-active-color-primary"
      >
        View All
        <app-keenicon name="arrow-right" class="fs-5"></app-keenicon>
      </a>
    </div>
  </div>
</div>
