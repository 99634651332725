import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseMessage } from '../../../shared/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root'
})
export class CoupontManagementService {
  couponFilter = new BehaviorSubject(0);
  couponTypeFilter = new BehaviorSubject('');
  constructor(private http:HttpClient) {

   }
   getCouponList(data:any):Observable<ResponseMessage>{
    let params = new HttpParams();
    params = params.append('page',data.page);
    params = params.append('per_page',data.per_page);
    params = params.append('is_active',data.is_active);
    params = params.append('coupon_type',data.coupon_type);
    return this.http.get<ResponseMessage>('get-coupons',{params:params});
   }
   deleteCoupon(id:any):Observable<ResponseMessage>{
    return this.http.delete<ResponseMessage>(`delete-coupon/${id}`);
   }
   getCoupnDetail(id:any):Observable<ResponseMessage>{
    return this.http.get<ResponseMessage>(`coupon-details/${id}`);
   }
   addUpdateCoupon(data:any):Observable<ResponseMessage>{
    return this.http.post<ResponseMessage>('add-coupon',data);
   }
}
