
<!-- Dashboard -->
<div class="menu-item me-lg-1">
  <a class="menu-link py-3" routerLinkActive="active menu-here" routerLink="/dashboard">
    <span class="menu-title" translate="MENU.DASHBOARD"></span>
  </a>
</div>

<!-- Layout Builder -->
<div class="menu-item me-lg-1">
  <a class="menu-link py-3" routerLinkActive="active menu-here" routerLink="/profile">
    <span class="menu-title">Profile
    </span>
  </a>
</div>
<div class="menu-item me-lg-1">
  <a class="menu-link py-3" routerLinkActive="active menu-here" routerLink="/vendor-management">
    <span class="menu-title">User Management
    </span>
  </a>
</div>
<!-- <div class="menu-item me-lg-1">
  <a class="menu-link py-3" routerLinkActive="active menu-here" routerLink="/user-role-management">
    <span class="menu-title">User Role Management
    </span>
  </a>
</div> -->
<div class="menu-item me-lg-1">
  <a class="menu-link py-3" routerLinkActive="active menu-here" routerLink="/device-management">
    <span class="menu-title">Device Management
    </span>
  </a>
</div>
<div class="menu-item me-lg-1">
  <a class="menu-link py-3" routerLinkActive="active menu-here" routerLink="/coupon-management">
    <span class="menu-title">Coupon Management
    </span>
  </a>
</div>
<div class="menu-item me-lg-1">
  <select class="form-select form-select-solid form-select-lg fw-bold" name="type" [(ngModel)]="selectedLocation"
    (change)="selectLocation()">
    <option value="">Select Location</option>
    @for(location of locationList;track location){
    <option [value]="location.business_location_id">{{location.name}}</option>
    }
  </select>
</div>

<!-- Crafted -->
<!-- <div class="menu-item menu-lg-down-accordion me-lg-1 menu-dropdown"
     data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start">
  <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/crafted')">
    <span class="menu-title">Crafted</span>
    <span class="menu-arrow d-lg-none"></span>
  </span>
  <div class="
      menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0
      py-lg-4
      w-lg-225px
    " data-kt-menu-dismiss="true" data-popper-placement="bottom-start">
    <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default:'click', lg: 'hover'}"
         data-kt-menu-placement="right-start">
      <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/crafted/pages')">
        <span class="menu-icon"><i
          class="bi fs-3 bi-archive"></i></span>
        <span class="menu-title">Pages</span>
        <span
          class="menu-arrow"></span>
      </span>
      <div class="
          menu-sub
          menu-sub-lg-down-accordion
          menu-sub-lg-dropdown
          menu-rounded-0
          py-lg-4
          w-lg-225px
        " data-kt-menu-dismiss="true">
        <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default:'click', lg: 'hover'}"
             data-kt-menu-placement="right-start">
          <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/crafted/pages/profile')">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span class="menu-title"
                  data-link="/crafted/pages/profile">Profile
            </span>
            <span class="menu-arrow"></span>
          </span>
          <div class="
              menu-sub
              menu-sub-lg-down-accordion
              menu-sub-lg-dropdown
              menu-rounded-0
              py-lg-4
              w-lg-225px
            " data-kt-menu-dismiss="true">
            <div class="menu-item me-lg-1">
              <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/pages/profile/overview">
                <span
                  class="menu-bullet">
                  <span class="bullet bullet-dot"></span>
                </span>
                <span
                  class="menu-title">Overview
                </span>
              </a>
            </div>
            <div class="menu-item me-lg-1">
              <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/pages/profile/projects">
                <span
                  class="menu-bullet">
                  <span class="bullet bullet-dot"></span>
                </span>
                <span
                  class="menu-title">Projects
                </span>
              </a>
            </div>

            <div class="menu-item me-lg-1">
              <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/pages/profile/campaigns">
                <span
                  class="menu-bullet">
                  <span class="bullet bullet-dot"></span>
                </span>
                <span
                  class="menu-title">Campaigns
                </span>
              </a>
            </div>

            <div class="menu-item me-lg-1">
              <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/pages/profile/documents">
                <span
                  class="menu-bullet">
                  <span class="bullet bullet-dot"></span>
                </span>
                <span
                  class="menu-title">Documents
                </span>
              </a>
            </div>

            <div class="menu-item me-lg-1">
              <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/pages/profile/connections">
                <span
                  class="menu-bullet">
                  <span class="bullet bullet-dot"></span>
                </span>
                <span
                  class="menu-title">Connections
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default:'click', lg: 'hover'}"
             data-kt-menu-placement="right-start">
          <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/crafted/pages/wizards')">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span class="menu-title"
                  data-link="/crafted/pages/wizards">Wizards
            </span>
            <span class="menu-arrow"></span>
          </span>
          <div class="
              menu-sub
              menu-sub-lg-down-accordion
              menu-sub-lg-dropdown
              menu-rounded-0
              py-lg-4
              w-lg-225px
            " data-kt-menu-dismiss="true">
            <div class="menu-item me-lg-1">
              <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/pages/wizards/horizontal">
                <span
                  class="menu-bullet">
                  <span class="bullet bullet-dot"></span>
                </span>
                <span
                  class="menu-title">Horizontal
                </span>
              </a>
            </div>
            <div class="menu-item me-lg-1">
              <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/pages/wizards/vertical">
                <span
                  class="menu-bullet">
                  <span class="bullet bullet-dot"></span>
                </span>
                <span
                  class="menu-title">Vertical
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default:'click', lg: 'hover'}"
         data-kt-menu-placement="right-start">
      <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/crafted/account')">
        <span class="menu-icon"><i
          class="bi fs-3 bi-person"></i></span>
        <span class="menu-title"
              data-link="/crafted/account">Accounts
        </span>
        <span class="menu-arrow"></span>
      </span>
      <div class="
          menu-sub
          menu-sub-lg-down-accordion
          menu-sub-lg-dropdown
          menu-rounded-0
          py-lg-4
          w-lg-225px
        " data-kt-menu-dismiss="true">
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/account/overview">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Overview
            </span>
          </a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/account/settings">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Settings
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default:'click', lg: 'hover'}"
         data-kt-menu-placement="right-start">
      <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/error')">
        <span class="menu-icon"><i
          class="bi fs-3 bi-sticky"></i></span>
        <span class="menu-title" data-link="/error">Errors</span>
        <span
          class="menu-arrow"></span>
      </span>
      <div class="
          menu-sub
          menu-sub-lg-down-accordion
          menu-sub-lg-dropdown
          menu-rounded-0
          py-lg-4
          w-lg-225px
        " data-kt-menu-dismiss="true">
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/error/404">
            <span class="menu-bullet">
              <span
                class="bullet bullet-dot"></span>
            </span>
            <span class="menu-title">Error 404</span>
          </a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/error/500">
            <span class="menu-bullet">
              <span
                class="bullet bullet-dot"></span>
            </span>
            <span class="menu-title">Error 500</span>
          </a>
        </div>
      </div>
    </div>
    <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default:'click', lg: 'hover'}"
         data-kt-menu-placement="right-start">
      <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/crafted/widgets')">
        <span class="menu-icon"><i
          class="bi fs-3 bi-layers"></i></span>
        <span class="menu-title"
              data-link="/crafted/widgets">Widgets
        </span>
        <span class="menu-arrow"></span>
      </span>
      <div class="
          menu-sub
          menu-sub-lg-down-accordion
          menu-sub-lg-dropdown
          menu-rounded-0
          py-lg-4
          w-lg-225px
        " data-kt-menu-dismiss="true">
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/widgets/lists">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Lists
            </span>
          </a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/widgets/statistics">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Statistics
            </span>
          </a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/widgets/charts">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Charts
            </span>
          </a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/widgets/mixed">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Mixed
            </span>
          </a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/widgets/tables">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Tables
            </span>
          </a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/crafted/widgets/feeds">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Feeds
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- Apps -->
<!-- <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
  data-kt-menu-placement="bottom-start">
  <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/apps')">
    <span class="menu-title">Apps</span>
    <span class="menu-arrow d-lg-none"></span>
  </span>
  <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px" data-kt-menu-dismiss="true">
    <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default:'click', lg: 'hover'}"
      data-kt-menu-placement="right-start">
      <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass('/apps/chat')"><span class="menu-icon">
        <app-keenicon name="element-plus" class="fs-md- text-white"></app-keenicon>
      </span>
      <span class="menu-title" data-link="/apps/chat">Chat</span><span class="menu-arrow"></span></span>
      <div class="
          menu-sub
          menu-sub-lg-down-accordion
          menu-sub-lg-dropdown
          menu-rounded-0
          py-lg-4
          w-lg-225px
        " data-kt-menu-dismiss="true">
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/apps/chat/private-chat"><span
              class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title">Private
              Chat</span></a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/apps/chat/group-chat"><span
              class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title">Group
              Chart</span></a>
        </div>
        <div class="menu-item me-lg-1">
          <a class="menu-link py-3" routerLinkActive="active" routerLink="/apps/chat/drawer-chat"><span
              class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title">Drawer
              Chart</span></a>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- MegaMenu -->
<!-- <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
       data-kt-menu-placement="bottom-start"> -->
<!-- <span class="menu-link py-3">
      <span class="menu-title">Mega menu</span>
      <span class="menu-arrow d-lg-none"></span>
    </span>
    <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 w-100 w-lg-850px"> -->
<!--begin:Dashboards menu-->
<!-- <div class="menu-active-bg pt-1 pb-3 px-3 py-lg-6 px-lg-6" data-kt-menu-dismiss="true"> -->
<!--begin:Row-->
<!-- <div class="row"> -->
<!--begin:Col-->
<!-- <div class="col-lg-6"> -->
<!--begin:Row-->
<!-- <div class="row"> -->
<!--begin:Col-->
<!-- <div class="col-lg-6 mb-3"> -->
<!--begin:Heading-->
<!-- <h4 class="fs-6 fs-lg-4 text-gray-800 fw-bold mt-3 mb-3 ms-4">Layouts</h4> -->
<!--end:Heading-->
<!--begin:Menu item-->
<!-- <div class="menu-item p-0 m-0"> -->
<!--begin:Menu link-->
<!-- <a (click)="setBaseLayoutType('light-sidebar')" class="menu-link">
                    <span class="menu-bullet">
                      <span class="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
                    </span>
                    <span class="menu-title">Light Sidebar</span>
                  </a> -->
<!--end:Menu link-->
<!-- </div> -->
<!--end:Menu item-->
<!--begin:Menu item-->
<!-- <div class="menu-item p-0 m-0"> -->
<!--begin:Menu link-->
<!-- <a (click)="setBaseLayoutType('dark-sidebar')" class="menu-link">
                    <span class="menu-bullet">
                      <span class="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
                    </span>
                    <span class="menu-title">Dark Sidebar</span>
                  </a> -->
<!--end:Menu link-->
<!-- </div> -->
<!--end:Menu item-->
<!--begin:Menu item-->
<!-- <div class="menu-item p-0 m-0"> -->
<!--begin:Menu link-->
<!-- <a (click)="setBaseLayoutType('light-header')" class="menu-link">
                    <span class="menu-bullet">
                      <span class="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
                    </span>
                    <span class="menu-title">Light Header</span>
                  </a> -->
<!--end:Menu link-->
<!-- </div> -->
<!--end:Menu item-->
<!--begin:Menu item-->
<!-- <div class="menu-item p-0 m-0"> -->
<!--begin:Menu link-->
<!-- <a (click)="setBaseLayoutType('dark-header')" class="menu-link">
                    <span class="menu-bullet">
                      <span class="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
                    </span>
                    <span class="menu-title">Dark Header</span>
                  </a> -->
<!--end:Menu link-->
<!-- </div> -->
<!--end:Menu item-->
<!-- </div> -->
<!--end:Col-->
<!--begin:Col-->
<!-- <div class="col-lg-6 mb-3"> -->
<!--begin:Heading-->
<!-- <h4 class="fs-6 fs-lg-4 text-gray-800 fw-bold mt-3 mb-3 ms-4">Toolbars</h4> -->
<!--end:Heading-->
<!--begin:Menu item-->
<!-- <div class="menu-item p-0 m-0"> -->
<!--begin:Menu link-->
<!-- <a (click)="setToolbar('classic')" class="menu-link">
                    <span class="menu-bullet">
                      <span class="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
                    </span>
                    <span class="menu-title">Classic</span>
                  </a> -->
<!--end:Menu link-->
<!-- </div> -->
<!--end:Menu item-->
<!--begin:Menu item-->
<!-- <div class="menu-item p-0 m-0"> -->
<!--begin:Menu link-->
<!-- <a (click)="setToolbar('saas')" class="menu-link">
                    <span class="menu-bullet">
                      <span class="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
                    </span>
                    <span class="menu-title">SaaS</span>
                  </a> -->
<!--end:Menu link-->
<!-- </div> -->
<!--end:Menu item-->
<!--begin:Menu item-->
<!-- <div class="menu-item p-0 m-0"> -->
<!--begin:Menu link-->
<!-- <a (click)="setToolbar('accounting')" class="menu-link">
                    <span class="menu-bullet">
                      <span class="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
                    </span>
                    <span class="menu-title">Accounting</span>
                  </a> -->
<!--end:Menu link-->
<!-- </div> -->
<!--end:Menu item-->
<!--begin:Menu item-->
<!-- <div class="menu-item p-0 m-0"> -->
<!--begin:Menu link-->
<!-- <a (click)="setToolbar('extended')" class="menu-link">
                    <span class="menu-bullet">
                      <span class="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
                    </span>
                    <span class="menu-title">Extended</span>
                  </a> -->
<!--end:Menu link-->
<!-- </div> -->
<!--end:Menu item-->
<!--begin:Menu item-->
<!-- <div class="menu-item p-0 m-0"> -->
<!--begin:Menu link-->
<!-- <a (click)="setToolbar('reports')" class="menu-link">
                    <span class="menu-bullet">
                      <span class="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
                    </span>
                    <span class="menu-title">Reports</span>
                  </a> -->
<!--end:Menu link-->
<!-- </div> -->
<!--end:Menu item-->
<!-- </div> -->
<!--end:Col-->
<!-- </div> -->
<!--end:Row-->
<!-- <div class="separator separator-dashed mx-lg-5 mt-2 mb-6"></div> -->
<!--begin:Layout Builder-->
<!-- <div class="d-flex flex-stack flex-wrap flex-lg-nowrap gap-2 mb-5 mb-lg-0 mx-lg-5">
              <div class="d-flex flex-column me-5">
                <div class="fs-6 fw-bold text-gray-800">Layout Builder</div>
                <div class="fs-7 fw-semibold text-muted">Customize view</div>
              </div>
              <a routerLink="/builder" class="btn btn-sm btn-primary fw-bold">Try
                Builder</a>
            </div> -->
<!--end:Layout Builder-->
<!-- </div> -->
<!--end:Col-->
<!--begin:Col-->
<!-- <div class="col-lg-6 mb-3 py-lg-3 pe-lg-8 d-flex align-items-center">
            <img src="./assets/media/stock/900x600/45.jpg" class="rounded mw-100" alt="">
          </div> -->
<!--end:Col-->
<!-- </div> -->
<!--end:Row-->
<!-- </div> -->
<!--end:Dashboards menu-->
<!-- </div> -->
<!-- </div> -->