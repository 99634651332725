<!-- begin::Header -->
<div class="card-header align-items-center border-0 mt-4">
  <h3 class="card-title align-items-start flex-column">
    <span class="fw-bolder text-gray-900">Latest Products</span>
    <span class="text-muted mt-1 fw-bold fs-7">Gifts and more</span>
  </h3>
  <div class="card-toolbar">
    <!-- begin::Menu -->
    <button
      type="button"
      class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <app-keenicon name="category" class="fs-2"></app-keenicon>
    </button>
    <app-dropdown-menu1></app-dropdown-menu1>
    <!-- end::Menu -->
  </div>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body pt-3">
  <!-- begin::Item -->
  <div class="d-flex align-items-sm-center mb-7">
    <!-- begin::Symbol -->
    <div class="symbol symbol-60px symbol-2by3 me-4">
      <div
        class="symbol-label"
        [style.background-image]="
          'url(./assets/media/stock/600x400/img-17.jpg)'
        "
      ></div>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Content -->
    <div
      class="d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2"
    >
      <!-- begin::Title -->
      <div class="flex-grow-1 my-lg-0 my-2 me-2">
        <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">
          Cup &amp; Green
        </a>
        <span class="text-muted fw-bold d-block pt-1">Visually stunning</span>
      </div>
      <!-- end::Title -->
      <!-- begin::Section -->
      <div class="d-flex align-items-center">
        <div class="me-6">
          <i class="fa fa-star-half-alt me-1 text-warning fs-5"></i>
          <span class="text-gray-800 fw-bolder">4.2</span>
        </div>
        <a href="#" class="btn btn-icon btn-light btn-sm border-0">
          <app-keenicon name="arrow-right" class="fs-2 text-primary"></app-keenicon>
        </a>
      </div>
      <!-- end::Section -->
    </div>
    <!-- end::Content -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-sm-center mb-7">
    <!-- begin::Symbol -->
    <div class="symbol symbol-60px symbol-2by3 me-4">
      <div
        class="symbol-label"
        [style.background-image]="
          'url(./assets/media/stock/600x400/img-10.jpg)'
        "
      ></div>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Content -->
    <div
      class="d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2"
    >
      <!-- begin::Title -->
      <div class="flex-grow-1 my-lg-0 my-2 me-2">
        <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">
          Pink Patterns
        </a>
        <span class="text-muted fw-bold d-block pt-1">Feminine all around</span>
      </div>
      <!-- end::Title -->
      <!-- begin::Section -->
      <div class="d-flex align-items-center">
        <div class="me-6">
          <i class="fa fa-star-half-alt me-1 text-warning fs-5"></i>
          <span class="text-gray-800 fw-bolder">5.0</span>
        </div>
        <a href="#" class="btn btn-icon btn-light btn-sm border-0">
          <app-keenicon name="arrow-right" class="fs-2 text-primary"></app-keenicon>
        </a>
      </div>
      <!-- end::Section -->
    </div>
    <!-- end::Content -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-sm-center mb-7">
    <!-- begin::Symbol -->
    <div class="symbol symbol-60px symbol-2by3 me-4">
      <div
        class="symbol-label"
        [style.background-image]="'url(./assets/media/stock/600x400/img-1.jpg)'"
      ></div>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Content -->
    <div
      class="d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2"
    >
      <!-- begin::Title -->
      <div class="flex-grow-1 my-lg-0 my-2 me-2">
        <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">
          Abstract Art
        </a>
        <span class="text-muted fw-bold d-block pt-1"
          >The will to capture readers</span
        >
      </div>
      <!-- end::Title -->
      <!-- begin::Section -->
      <div class="d-flex align-items-center">
        <div class="me-6">
          <i class="fa fa-star-half-alt me-1 text-warning fs-5"></i>
          <span class="text-gray-800 fw-bolder">5.7</span>
        </div>
        <a href="#" class="btn btn-icon btn-light btn-sm border-0">
          <app-keenicon name="arrow-right" class="fs-2 text-primary"></app-keenicon>
        </a>
      </div>
      <!-- end::Section -->
    </div>
    <!-- end::Content -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-sm-center">
    <!-- begin::Symbol -->
    <div class="symbol symbol-60px symbol-2by3 me-4">
      <div
        class="symbol-label"
        [style.background-image]="'url(./assets/media/stock/600x400/img-9.jpg)'"
      ></div>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Content -->
    <div
      class="d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2"
    >
      <!-- begin::Title -->
      <div class="flex-grow-1 my-lg-0 my-2 me-2">
        <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">
          Desserts platter
        </a>
        <span class="text-muted fw-bold d-block pt-1">
          Food trends &amp; inspirations
        </span>
      </div>
      <!-- end::Title -->
      <!-- begin::Section -->
      <div class="d-flex align-items-center">
        <div class="me-6">
          <i class="fa fa-star-half-alt me-1 text-warning fs-5"></i>
          <span class="text-gray-800 fw-bolder">3.7</span>
        </div>
        <a href="#" class="btn btn-icon btn-light btn-sm border-0">
          <app-keenicon name="arrow-right" class="fs-2 text-primary"></app-keenicon>
        </a>
      </div>
      <!-- end::Section -->
    </div>
    <!-- end::Content -->
  </div>
  <!-- end::Item -->
</div>
<!-- end::Body -->
