import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthService, UserType } from '../../../../../../modules/auth/services/auth.service';
import { Router } from '@angular/router';
import { MenuComponent } from '../../../../../kt/components/MenuComponent';
import { environment } from '../../../../../../../environments/environment';
import { SharedService } from '../../../../../../shared/service/shared.service';
// import { TranslationService } from '../../../../../../modules/i18n';
// import { AuthService, UserType } from '../../../../../../modules/auth';

@Component({
  selector: 'app-user-inner',
  templateUrl: './user-inner.component.html',
})
export class UserInnerComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  class = `menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px`;
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';

  // language: LanguageFlag;
  user$!: Observable<UserType>;
  langs = languages;
  private unsubscribe: Subscription[] = [];

  constructor(
    private auth: AuthService,
    private route: Router,
    private sharedService: SharedService
    // private translationService: TranslationService
  ) { }

  ngOnInit(): void {
    this.user$ = this.auth.currentUserSubject.asObservable();
    // MenuComponent.createInstances('[data-kt-menu-trigger]');
    // this.setLanguage(this.translationService.getSelectedLanguage());
  }
  switchToUser() {
    const token = localStorage.getItem('vendor_token');
    const url = environment.vendorUserUrl + `?token=${token}`;
    this.sharedService.redirectToURL(url, '_blank');
  }
  logout() {
    localStorage.removeItem('vendor_token');
    localStorage.removeItem('vendorUserDetail');
    this.route.navigate(['/vendor/login']);
    document.location.reload();
  }

  selectLanguage(lang: string) {
    // this.translationService.setLanguage(lang);
    this.setLanguage(lang);
    // document.location.reload();
  }

  setLanguage(lang: string) {
    this.langs.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        // this.language = language;
      } else {
        language.active = false;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}

interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: './assets/media/flags/united-states.svg',
  },
  {
    lang: 'zh',
    name: 'Mandarin',
    flag: './assets/media/flags/china.svg',
  },
  {
    lang: 'es',
    name: 'Spanish',
    flag: './assets/media/flags/spain.svg',
  },
  {
    lang: 'ja',
    name: 'Japanese',
    flag: './assets/media/flags/japan.svg',
  },
  {
    lang: 'de',
    name: 'German',
    flag: './assets/media/flags/germany.svg',
  },
  {
    lang: 'fr',
    name: 'French',
    flag: './assets/media/flags/france.svg',
  },
];
