<div class="row g-5 g-xl-8">
  <div class="card mb-5 mb-xl-8">
    <!-- begin::Header -->
    <div class="d-flex align-items-center position-relative mt-7 ms-7">
      <app-keenicon name="magnifier" class="fs-3 position-absolute ms-5"></app-keenicon>
      <input type="text" data-action="filter" class="form-control form-control-solid w-250px ps-12"
        placeholder="Search Devices" (input)="searchDevice($event)">
    </div>
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Devices</span>
        <!-- <span class="text-muted mt-1 fw-bold fs-7">Over 500 new products</span> -->
      </h3>
      <div class="card-toolbar" (click)="addDevice()">
        <a class="btn btn-sm btn-light-primary d-flex align-items-center gap-2">
          <app-keenicon name="plus" class="fs-3"></app-keenicon>
          Add Device
        </a>
      </div>

    </div>

    <!-- end::Header -->
    <!-- begin::Body -->
    <div class="card-body py-3" infinite-scroll (scrolled)="getScrolledDown()" [immediateCheck]="true"
      [scrollWindow]="false" [infiniteScrollDistance]="3" [infiniteScrollThrottle]="0">
      <!-- begin::Table container -->
      <div class="table-responsive">
        <!-- begin::Table -->
        <table class="table align-middle gs-0 gy-4">
          <!-- begin::Table head -->
          <thead>
            <tr class="fw-bolder text-muted bg-light">
              <th class="ps-4 min-w-325px rounded-start">Name</th>
              <th class="min-w-125px">Status</th>
              <th class="min-w-125px">Battery</th>
              <th class="min-w-200px">Location</th>
              <th class="min-w-150px">Last update</th>
              <th class="min-w-200px text-end rounded-end">
                <span class="pe-5 me-3">
                  Action
                </span>
              </th>
            </tr>
          </thead>
          <!-- end::Table head -->
          <!-- begin::Table body -->
          <tbody [ngClass]="deviceListData.length==0?'text-center':''">
            @if(deviceListData.length>0){
            @for (deviceInfo of deviceListData; track $index) {
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <div class="d-flex justify-content-start flex-column">
                    <span class="text-muted fw-bold text-muted d-block fs-7 ps-5">
                      {{deviceInfo.name}}
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <span class="text-muted fw-bold text-muted d-block fs-7">
                  {{deviceInfo.status == 1 ?'Active':'InActive'}}
                </span>
              </td>
              <td>
                <span class="text-muted fw-bold text-muted d-block fs-7"> {{deviceInfo.battery}}%</span>
              </td>
              <td>
                <span class="text-muted fw-bold text-muted d-block fs-7">
                  {{deviceInfo.appartment}} {{', '+deviceInfo.street_name}}
                </span>
              </td>
              <td>
                <span class="badge badge-light-primary fs-7 fw-bold"> {{deviceInfo.last_update | dateTime24Hr}}</span>
              </td>
              <td class="text-end">
                <a (click)="editDevice(deviceInfo.id)"
                  class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                  <app-keenicon name="pencil" class="fs-3"></app-keenicon>
                </a>
                <a (click)="deleteDevice(deviceInfo.id)"
                  class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                  <app-keenicon name="trash" class="fs-3"></app-keenicon>
                </a>
              </td>
            </tr>
            }
            }@else {
            <tr>
              <td colspan="6">
                <p class="mb-0">No Records Available</p>
              </td>
            </tr>
            }

          </tbody>
          <!-- end::Table body -->
        </table>
        <!-- end::Table -->
      </div>
      <!-- end::Table container -->
    </div>
    <!-- begin::Body -->

  </div>
</div>
<app-modal [modalConfig]="modalconfig" #deviceDeletModal>Are you sure do you want to delete this Device?</app-modal>