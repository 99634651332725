<!--begin::Menu wrapper-->
<div id="kt_app_sidebar_menu_scroll" class="scroll-y my-5 mx-3" data-kt-scroll="true" data-kt-scroll-activate="true"
  data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
  data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true">
  <!--begin::Menu-->
  <div class="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6" id="#kt_app_sidebar_menu"
    data-kt-menu="true" data-kt-menu-expand="false">
    <!-- Dashboard -->
    <div class="menu-item">
      <a class="menu-link without-sub" routerLink="/dashboard" routerLinkActive="active">
        <span class="menu-icon">
          <app-keenicon name="element-11" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title" translate="MENU.DASHBOARD"></span>
      </a>
    </div>
    <div class="menu-item">
      <a class="menu-link without-sub" routerLink="/profile" routerLinkActive="active">
        <span class="menu-icon">
          <app-keenicon name="element-11" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title">Profile</span>
      </a>
    </div>
    <div class="menu-item">
      <a class="menu-link without-sub" routerLink="/vendor-management" routerLinkActive="active">
        <span class="menu-icon">
          <app-keenicon name="element-11" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title">User  Management</span>
      </a>
    </div>
    <!-- <div class="menu-item">
      <a class="menu-link without-sub" routerLink="/user-role-management" routerLinkActive="active">
        <span class="menu-icon">
          <app-keenicon name="element-11" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title">User Role  Management</span>
      </a>
    </div> -->
    <div class="menu-item">
      <a class="menu-link without-sub" routerLink="/device-management" routerLinkActive="active">
        <span class="menu-icon">
          <app-keenicon name="element-11" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title">Device Management</span>
      </a>
    </div>
    <div class="menu-item">
      <a class="menu-link without-sub" routerLink="/coupon-management" routerLinkActive="active">
        <span class="menu-icon">
          <app-keenicon name="element-11" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title">Coupon Management</span>
      </a>
    </div>

    <!-- Layout builder -->
    <!-- <div class="menu-item">
      <a class="menu-link without-sub" routerLink="/builder" routerLinkActive="active">
        <span class="menu-icon">
          <app-keenicon name="switch" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title">Layout Builder</span>
      </a>
    </div> -->
    <!-- Separator -->
    <!-- <div class="menu-item">
      <div class="menu-content pt-8 pb-2">
        <span class="menu-section text-muted text-uppercase fs-8 ls-1">Crafted</span>
      </div>
    </div> -->

    <!-- Pages -->
    <!-- <div class="menu-item menu-accordion" data-kt-menu-trigger="click" routerLinkActive="here show">
      <span class="menu-link">
        <span class="menu-icon">
          <app-keenicon name="element-plus" class="fs-2"></app-keenicon>
        </span>
        <span
          class="menu-title">Pages
        </span>
        <span class="menu-arrow"></span>
      </span>
      <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
        <div class="menu-item menu-accordion" routerLinkActive="here show" data-kt-menu-trigger="click">
          <span class="menu-link">
            <span class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title" data-link="/crafted/pages/profile">Profile
            </span>
            <span
              class="menu-arrow"></span>
          </span>
          <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
            <div class="menu-item">
              <a class="menu-link without-sub" routerLinkActive="active"
                 routerLink="/crafted/pages/profile/overview">
                <span class="menu-bullet">
                  <span
                    class="bullet bullet-dot"></span>
                </span>
                <span class="menu-title">Overview</span>
              </a>
            </div>
            <div class="menu-item">
              <a class="menu-link without-sub" routerLinkActive="active"
                 routerLink="/crafted/pages/profile/projects">
                <span class="menu-bullet">
                  <span
                    class="bullet bullet-dot"></span>
                </span>
                <span class="menu-title">Projects</span>
              </a>
            </div>
            <div class="menu-item">
              <a class="menu-link without-sub" routerLinkActive="active"
                 routerLink="/crafted/pages/profile/campaigns">
                <span class="menu-bullet">
                  <span
                    class="bullet bullet-dot"></span>
                </span>
                <span class="menu-title">Campaigns</span>
              </a>
            </div>
            <div class="menu-item">
              <a class="menu-link without-sub" routerLinkActive="active"
                 routerLink="/crafted/pages/profile/documents">
                <span class="menu-bullet">
                  <span
                    class="bullet bullet-dot"></span>
                </span>
                <span class="menu-title">Documents</span>
              </a>
            </div>
            <div class="menu-item">
              <a class="menu-link without-sub" routerLinkActive="active"
                 routerLink="/crafted/pages/profile/connections">
                <span class="menu-bullet">
                  <span
                    class="bullet bullet-dot"></span>
                </span>
                <span class="menu-title">Connections</span>
              </a>
            </div>
          </div>
        </div>
        <div class="menu-item menu-accordion" routerLinkActive="here show" data-kt-menu-trigger="click">
          <span class="menu-link">
            <span class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title" data-link="/crafted/pages/wizards">Wizards
            </span>
            <span
              class="menu-arrow"></span>
          </span>
          <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
            <div class="menu-item">
              <a class="menu-link without-sub" routerLinkActive="active"
                 routerLink="/crafted/pages/wizards/horizontal">
                <span class="menu-bullet">
                  <span
                    class="bullet bullet-dot"></span>
                </span>
                <span class="menu-title">Horizontal</span>
              </a>
            </div>
            <div class="menu-item">
              <a class="menu-link without-sub" routerLinkActive="active"
                 routerLink="/crafted/pages/wizards/vertical">
                <span class="menu-bullet">
                  <span
                    class="bullet bullet-dot"></span>
                </span>
                <span class="menu-title">Vertical</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Accounts -->
    <!-- <div class="menu-item menu-accordion" data-kt-menu-trigger="click" routerLinkActive="here show">
      <span class="menu-link">
        <span class="menu-icon">
          <app-keenicon name="profile-circle" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title"
              data-link="/crafted/account">Accounts
        </span>
        <span class="menu-arrow"></span>
      </span>
      <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/crafted/account/overview">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Overview
            </span>
          </a>
        </div>
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/crafted/account/settings">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Settings
            </span>
          </a>
        </div>
      </div>
    </div> -->

    <!-- Errors -->
    <!-- <div class="menu-item menu-accordion" data-kt-menu-trigger="click" routerLinkActive="here show">
      <span class="menu-link">
        <span class="menu-icon">
          <app-keenicon name="phone" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title"
              data-link="/error">Errors
        </span>
        <span class="menu-arrow"></span>
      </span>
      <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
        <div class="menu-item">
          <a class="menu-link without-sub" routerLink="/error/404" routerLinkActive="active">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span class="menu-title">Error
              404
            </span>
          </a>
        </div>
        <div class="menu-item">
          <a class="menu-link without-sub" routerLink="/error/500" routerLinkActive="active">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span class="menu-title">Error
              500
            </span>
          </a>
        </div>
      </div>
    </div> -->

    <!-- Widgets -->
    <!-- <div class="menu-item menu-accordion" data-kt-menu-trigger="click" routerLinkActive="here show">
      <span class="menu-link">
        <span class="menu-icon">
          <app-keenicon name="element-7" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title"
              data-link="/crafted/widgets">Widgets
        </span>
        <span class="menu-arrow"></span>
      </span>
      <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/crafted/widgets/lists">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Lists
            </span>
          </a>
        </div>
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/crafted/widgets/statistics">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Statistics
            </span>
          </a>
        </div>
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/crafted/widgets/charts">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Charts
            </span>
          </a>
        </div>
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/crafted/widgets/mixed">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Mixed
            </span>
          </a>
        </div>
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/crafted/widgets/tables">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Tables
            </span>
          </a>
        </div>
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/crafted/widgets/feeds">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span
              class="menu-title">Feeds
            </span>
          </a>
        </div>
      </div>
    </div> -->

    <!-- Separator -->
    <!-- <div class="menu-item">
      <div class="menu-content pt-8 pb-2">
        <span class="menu-section text-muted text-uppercase fs-8 ls-1">Apps</span>
      </div>
    </div> -->

    <!-- Chat -->
    <!-- <div class="menu-item menu-accordion" data-kt-menu-trigger="click" routerLinkActive="here show">
      <span class="menu-link">
        <span class="menu-icon">
          <app-keenicon name="message-text-2" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title"
              data-link="/apps/chat">Chat
        </span>
        <span class="menu-arrow"></span>
      </span>
      <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/apps/chat/private-chat">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span class="menu-title">Private Chat
            </span>
          </a>
        </div>
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/apps/chat/group-chat">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span class="menu-title">Group Chat
            </span>
          </a>
        </div>
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/apps/chat/drawer-chat">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span class="menu-title">Drawer Chat
            </span>
          </a>
        </div>
      </div>
    </div> -->

    <!-- Customers -->
    <!-- <div class="menu-item menu-accordion" data-kt-menu-trigger="click" routerLinkActive="here show">
      <span class="menu-link">
        <span class="menu-icon">
          <app-keenicon name="profile-user" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title" data-link="/apps">User Management
        </span>
        <span class="menu-arrow"></span>
      </span>
      <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/apps/users">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span class="menu-title">Users
            </span>
          </a>
        </div>
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/apps/roles">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span class="menu-title">Roles
            </span>
          </a>
        </div>
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/apps/permissions">
            <span
              class="menu-bullet">
              <span class="bullet bullet-dot"></span>
            </span>
            <span class="menu-title">Permissions
            </span>
          </a>
        </div>
      </div>
    </div> -->
  </div>
  <!--end::Menu-->
</div>
<!--end::Menu wrapper-->