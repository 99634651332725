<!-- begin::Header -->
<div class="card-header border-0">
  <h3 class="card-title fw-bolder text-gray-900">Notifications</h3>
  <div class="card-toolbar">
    <!-- begin::Menu -->
    <button
      type="button"
      class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <app-keenicon name="category" class="fs-2"></app-keenicon>
    </button>
    <app-dropdown-menu1></app-dropdown-menu1>
    <!-- end::Menu -->
  </div>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body pt-0">
  <!-- begin::Item -->
  <div class="d-flex align-items-center bg-light-warning rounded p-5 mb-7">
    <!-- begin::Icon -->
    <span class="svg-icon text-warning me-5">
      <app-keenicon name="abstract-26" class="fs-1"></app-keenicon>
    </span>
    <!-- end::Icon -->
    <!-- begin::Title -->
    <div class="flex-grow-1 me-2">
      <a href="#" class="fw-bolder text-gray-800 text-hover-primary fs-6">
        Group lunch celebration
      </a>
      <span class="text-muted fw-bold d-block">Due in 2 Days</span>
    </div>
    <!-- end::Title -->
    <!-- begin::Lable -->
    <span class="fw-bolder text-warning py-1">+28%</span>
    <!-- end::Lable -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center bg-light-success rounded p-5 mb-7">
    <!-- begin::Icon -->
    <span class="svg-icon text-success me-5">
      <app-keenicon name="abstract-26" class="fs-1"></app-keenicon>
    </span>
    <!-- end::Icon -->
    <!-- begin::Title -->
    <div class="flex-grow-1 me-2">
      <a href="#" class="fw-bolder text-gray-800 text-hover-primary fs-6">
        Navigation optimization
      </a>
      <span class="text-muted fw-bold d-block">Due in 2 Days</span>
    </div>
    <!-- end::Title -->
    <!-- begin::Lable -->
    <span class="fw-bolder text-success py-1">+50%</span>
    <!-- end::Lable -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center bg-light-danger rounded p-5 mb-7">
    <!-- begin::Icon -->
    <span class="svg-icon text-danger me-5">
      <app-keenicon name="abstract-26" class="fs-1"></app-keenicon>
    </span>
    <!-- end::Icon -->
    <!-- begin::Title -->
    <div class="flex-grow-1 me-2">
      <a href="#" class="fw-bolder text-gray-800 text-hover-primary fs-6">
        Rebrand strategy planning
      </a>
      <span class="text-muted fw-bold d-block">Due in 5 Days</span>
    </div>
    <!-- end::Title -->
    <!-- begin::Lable -->
    <span class="fw-bolder text-danger py-1">-27%</span>
    <!-- end::Lable -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center bg-light-info rounded p-5">
    <!-- begin::Icon -->
    <span class="svg-icon text-info me-5">
      <app-keenicon name="abstract-26" class="fs-1"></app-keenicon>
    </span>
    <!-- end::Icon -->
    <!-- begin::Title -->
    <div class="flex-grow-1 me-2">
      <a href="#" class="fw-bolder text-gray-800 text-hover-primary fs-6">
        Product goals strategy
      </a>
      <span class="text-muted fw-bold d-block">Due in 7 Days</span>
    </div>
    <!-- end::Title -->
    <!-- begin::Lable -->
    <span class="fw-bolder text-info py-1">+8%</span>
    <!-- end::Lable -->
  </div>
  <!-- end::Item -->
</div>
<!-- end::Body -->
