<!--begin::Wrapper-->
@if(sharedService.showToolBar()){
  <div class="d-flex align-items-center flex-shrink-0" [ngClass]="route.url!='/device-management'?'d-none':''">
    <!--begin::Label-->
    <!-- <span class="fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block">Filter By:</span> -->
    <!--end::Label-->
  
    <div class="flex-shrink-0 ">
      <ul class="nav">
        <!-- <li class="nav-item">
          <a class="nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light active fw-semibold fs-7 px-4 me-1"
            data-bs-toggle="tab" href="javascript:void(0)">Status</a>
        </li> -->
  
        <li class="nav-item">
          <select class="form-select form-select-sm w-md-150px form-select-solid" data-control="select2" (change)="changeFilter($event)">
            <option value="0">Select Status</option>
            <option value="1">Active</option>
            <option value="2">InActive</option>
          </select>
        </li>
  
        <!-- <li class="nav-item">
          <a class="nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4"
            data-bs-toggle="tab" href="#">Day</a>
        </li> -->
      </ul>
    </div>
  </div>
  <!--end::Wrapper-->
  
  <!--begin::Separartor-->
  <div class="bullet bg-secondary h-35px w-1px mx-5"></div>
  <!--end::Separartor-->
  
  <!--begin::Wrapper-->
  <div class="d-flex align-items-center" [ngClass]="route.url!='/device-management'?'d-none':''">
    <!--begin::Label-->
    <!-- <span class="fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block">Sort By:</span> -->
    <!--end::Label-->
  
    <!--begin::Select-->
    <select class="form-select form-select-sm w-md-125px form-select-solid" data-control="select2"
      data-placeholder="Latest" data-hide-search="true" (change)="sortDevice($event)">
      <option value="0">Sort By</option>
      <option value="1">Asc</option>
      <option value="2">Desc</option>
      <!-- <option value="3">Done</option> -->
    </select>
    <!--end::Select-->
  
    <!--begin::Actions-->
    <div class="d-flex align-items-center ms-3" [ngClass]="route.url == '/device-management'?'d-none':''">
      <button type="button" class="btn btn-sm btn-icon btn-light-primary me-3" data-bs-toggle="tooltip"
        data-bs-placement="top" title="Enable grid view">
        <app-keenicon name="element-11" class="fs-2 text-primary"></app-keenicon>
      </button>
  
      <button type="button" class="btn btn-sm btn-icon btn-light" data-bs-toggle="tooltip" data-bs-placement="top"
        title="Enable row view">
        <app-keenicon name="abstract-14" class="fs-2 text-gray400"></app-keenicon>
      </button>
    </div>
    <!--end::Actions-->
  </div>
  <!--end::Wrapper-->

  <!--Filter for CouponMangement-->
  <div class="d-flex align-items-center flex-shrink-0" [ngClass]="route.url!='/coupon-management'?'d-none':''">
    <!--begin::Label-->
    <!-- <span class="fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block">Filter By:</span> -->
    <!--end::Label-->
  
    <div class="flex-shrink-0 ">
      <ul class="nav">
        <li>
          <select class="form-select form-select-sm w-md-125px form-select-solid" data-control="select2"
          data-placeholder="Latest" data-hide-search="true" (change)="filterCoupon($event)">
          <option value="-1">Filter By</option>
          <option value="0">All</option>
          <option value="1">Utilized</option>
          <!-- <option value="3">Done</option> -->
        </select>
        </li>
        <li>
          <select class="form-select form-select-sm w-md-150px form-select-solid" data-control="select2"
          data-placeholder="Latest" data-hide-search="true" (change)="filterCouponType($event)">
          <option value="-1">Coupon Type</option>
          <option value="1">Coupon</option>
          <option value="2">Gift Card</option>
          <!-- <option value="3">Done</option> -->
        </select>
        </li>
      </ul>
    </div>
  </div>
 
}
